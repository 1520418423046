export const PACKAGE_TRAININGS = [
  1018,
  1020,
  1022,
  1025,
  1158
];

export const DISABLED_TRAININGS = [
  1018,
  1147,
  // 1247
  1251,
  1250,
  1264,
  1273,
  1271,
  1299,
  1293,
  1309,
  1310,
  1295,
  // 1321
  1329,
  1323,
  1326
];

export const FORCE_NOTIFY_TRAININGS = [
  1184,
  // 1185
  // 1201,
  1167,
  1216,
  1213,
  // 1198
  // 1247

]


export const shortDays = [
  'Su',
  'M',
  'T',
  'W',
  'Th',
  'F',
  'Sa'
];

export const GradeOptions = (() => {
  const grades = [];
  let year = new Date().getFullYear();

  const JULY = 6
  if (new Date().getMonth() >= JULY) {
    year++;
  }

  for (let i = 12; i >= 4; i--) {
    grades.push({
      name: i + 'th Grade (' + year + ')',
      value: i + ''
    })
    year++;
  }

  grades.push({ name: '3rd Grade (' + year + ')', value: 3 + '' });
  year++;
  grades.push({ name: '2nd Grade (' + year + ')', value: 2 + '' });
  year++;
  grades.push({ name: '1st Grade (' + year + ')', value: 1 + '' });
  year++;

  grades.push({ name: 'Kindergarten (' + year + ')', value: 'K' });
  grades.push({ name: 'Pre-K', value: 'Pre-K' });
  grades.push({ name: 'College', value: 'College' });
  grades.push({ name: 'Post-College', value: 'Post-College' });

  return grades;
})();

export const YearsToGrade = ((year) => {
  let refYear = new Date().getFullYear();
  const JULY = 6
  if (new Date().getMonth() >= JULY) {
    refYear++;
  }
  const difference = (+year) - (+refYear);

  const gradeName = difference < -4
    ? 'Post-College'
    : difference >= -4 && difference < 0
      ? 'College'
      : difference >= 0 && difference < 9
        ? 12 - difference + 'th Grade'
        : difference === 9
          ? '3rd Grade'
          : difference === 10
            ? '2nd Grade'
            : difference === 11
              ? '1st Grade'
              : difference === 12
                ? 'Kindergarten'
                : 'Pre-K';

  return gradeName;
})


export const GradeYears = (() => {
  const grades = [];
  let year = new Date().getFullYear();
  for (let i = 12; i >= 4; i--) {
    grades.push({
      label: year,
      value: i + ''
    })
    year++;
  }

  grades.push({ label: year, value: 3 + '' });
  year++;
  grades.push({ label: year, value: 2 + '' });
  year++;
  grades.push({ label: year, value: 1 + '' });
  year++;

  grades.push({ label: year, value: 'K' });
  grades.push({ label: 'Pre-K', value: 'Pre-K' });
  grades.push({ label: 'College', value: 'College' });
  grades.push({ label: 'Post-College', value: 'Post-College' });

  return grades;
})();

export const athleteTypes = [
  { label: 'All', value: 'all' },
  { label: 'Coach', value: 'coach' },
  { label: 'Current College Athlete', value: 'current_college_athlete' },
  { label: 'D1 College Coach', value: 'd1_college_coach' },
  { label: 'D2 College Coach', value: 'd2_college_coach' },
  { label: 'D3 College Coach', value: 'd3_college_coach' },
  { label: 'JuCo Coach', value: 'juco_coach' },
  { label: 'NAIA Coach', value: 'naia_coach' },
  { label: 'Trainer', value: 'trainer' },
];

export const internshipUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSfm2pn0pvQbbElbgXd3ASvgQ8UK837IUIj7emBPjxbHqVOzbQ/viewform';

export const genders = [
  { label: 'All', value: 'all' },
  { label: 'Mens', value: 'mens' },
  { label: 'Womens', value: 'womens' },
];

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

const getClasses = () => {
  let arr = [];
  let i = 2010;
  for (; i <= 2032; i++) {
    arr.push({ label: i, value: i });
  }
  return arr;
};

export const passingYear = getClasses();

export const entryColor = {
  VIRTUALCOLOR: '#149617',
  GROUPCOLOR: '#FD8924',
  INDIVIDUALCOLOR: '#3B86FC',
};

export const states = [
  { label: 'AK', value: 'Alabama' },
  { label: 'AL', value: 'Alaska' },
  { label: 'AR', value: 'Arizona' },
  { label: 'AZ', value: 'Arkansas' },
  { label: 'CA', value: 'California' },
  { label: 'CO', value: 'Colorado' },
  { label: 'CT', value: 'Connecticut' },
  { label: 'DE', value: 'Delaware' },
  { label: 'FL', value: 'Florida' },
  { label: 'GA', value: 'Georgia' },
  { label: 'HI', value: 'Hawaii' },
  { label: 'IA', value: 'Idaho' },
  { label: 'ID', value: 'Illinois' },
  { label: 'IL', value: 'Indiana' },
  { label: 'IN', value: 'Iowa' },
  { label: 'KS', value: 'Kansas' },
  { label: 'KY', value: 'Kentucky' },
  { label: 'LA', value: 'Louisiana' },
  { label: 'MA', value: 'Maine' },
  { label: 'MD', value: 'Maryland' },
  { label: 'ME', value: 'Massachusetts' },
  { label: 'MI', value: 'Michigan' },
  { label: 'MN', value: 'Minnesota' },
  { label: 'MO', value: 'Mississippi' },
  { label: 'MS', value: 'Missouri' },
  { label: 'MT', value: 'Montana' },
  { label: 'NC', value: 'Nebraska' },
  { label: 'ND', value: 'Nevada' },
  { label: 'NE', value: 'New Hampshire' },
  { label: 'NH', value: 'New Jersey' },
  { label: 'NJ', value: 'New Mexico' },
  { label: 'NM', value: 'New York' },
  { label: 'NV', value: 'North Carolina' },
  { label: 'NY', value: 'North Dakota' },
  { label: 'OH', value: 'Ohio' },
  { label: 'OK', value: 'Oklahoma' },
  { label: 'OR', value: 'Oregon' },
  { label: 'PA', value: 'Pennsylvania' },
  { label: 'RI', value: 'Rhode Island' },
  { label: 'SC', value: 'South Carolina' },
  { label: 'SD', value: 'South Dakota' },
  { label: 'TN', value: 'Tennessee' },
  { label: 'TX', value: 'Texas' },
  { label: 'UT', value: 'Utah' },
  { label: 'VA', value: 'Vermont' },
  { label: 'VT', value: 'Virginia' },
  { label: 'WA', value: 'Washington' },
  { label: 'WI', value: 'West Virginia' },
  { label: 'WV', value: 'Wisconsin' },
  { label: 'WY', value: 'Wyoming' },
];

export const trainingOption = {
  VIRTUAL: 'Virtual',
  INDIVIDUAL: 'Individual',
  GROUP: 'Group',
};

export const customStyles = {
  control: styles => ({
    ...styles,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderRadius: 7,
  }),
  placeholder: styles => ({ ...styles, color: '#A1AFBF' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? 'black' : isSelected ? '#cccc' : isFocused ? 'gray' : null,
      color: isDisabled ? '#ccc' : isSelected ? 'black' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

export const removeArrowInSelect = {
  dropdownIndicator: defaultStyles => ({
    ...defaultStyles,
    '& svg': { display: 'none' },
  }),
};

export const genderOptions = [
  { label: 'Female', value: 2 },
  { label: 'Male', value: 1 },
];

export const removeSeparator = {
  IndicatorSeparator: () => null,
};

export const privacyPolicyProcessInfoTableData = [
  {
    statement:
      'To respond to your request or to fulfill the purpose for which you provide the information',
    purposes: ['Performance of a contract', 'Your consent'],
  },
  {
    statement: 'To personalize and improve your experience with the Site',
    purposes: ['Legitimate interest'],
  },
  {
    statement: 'To ensure technical functionality and proper operation of the Site',
    purposes: ['Legitimate interest'],
  },
  {
    statement: 'To improve our services or to develop new services',
    purposes: ['Legitimate interest'],
  },
  {
    statement:
      'To communicate with you for product or services-related purposes, such as regarding support issues and customer service inquiries',
    purposes: ['Performance of a contract', 'Legitimate interest', 'Consent'],
  },
  {
    statement:
      'To communicate with you for the following purposes: to respond to a request, marketing, remarketing, research, or other promotional purposes, via email, notifications or other messages, consistent with any permissions you may have communicated to us',
    purposes: ['Consent', 'Legitimate interest'],
  },
  {
    statement: 'To comply with our legal obligations',
    purposes: ['Legitimate interest'],
  },
  {
    statement: 'To carry out our obligations and enforce our rights',
    purposes: ['Legitimate interest', 'Contract'],
  },
  {
    statement: 'In any other way described when the information is collected',
    purposes: ['Legitimate interest', 'Consent'],
  },
];

export const privacyPolicyPersonalInfoDiscloseTableData = [
  {
    statement:
      'Identifiers: Name, postal address, IP address, email address, driver’s license number, telephone number',
    sources: ['you', 'technologically gathered from you', 'service providers', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'provide services',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement:
      'Name, address, bank account number, credit card number, debit card number, or any other financial information',
    sources: ['you', 'service providers', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Purchasing or consuming histories, tendencies, or preferences',
    sources: ['you', 'service providers', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'provide services to customers',
      'improve services',
      'fulfill contractual obligations',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Internet/network activity',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Geolocation data',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'improve customer experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Inferences drawn from other personal information',
    sources: ['business partners', 'service providers'],
    businessPurposes: [
      'provide services',
      'marketing and remarketing',
      'improve customer experience',
      'improve service offerings',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
];

export const CaliforniaResidentsNoticeTableData = [
  {
    statement: 'Identifiers',
    sources: ['you', 'technologically gathered from you', 'service provider', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'provide services',
      'fulfill contractual obligations',
    ],
    toWhomDisclosedList: ['service providers', 'business partners'],
  },
  {
    statement: 'CA Customer Records Statute categories',
    sources: ['you', 'service provider', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'provide services',
      'fulfill contractual obligations',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'analytics and advertising partners',
    ],
  },
  {
    statement: 'Protected Class Characteristics',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Commercial Information',
    sources: ['you', 'service provider', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'provide services to customers',
      'improve services',
    ],
    toWhomDisclosedList: ['service providers', 'business partners'],
  },
  {
    statement: 'Biometric Information',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Internet/network activity',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'advertising and remarketing partners',
    ],
  },
  {
    statement: 'Geolocation data',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'improve customer experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: ['analytics and advertising partners', 'business partners'],
  },
  {
    statement: 'Sensory data',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Professional/employment-related information',
    sources: ['you'],
    businessPurposes: [
      'facilitate transactions',
      'provide services to customers',
      'improve services',
      'fulfill contractual obligations',
      'respond to inquiries',
    ],
    toWhomDisclosedList: ['analytics and advertising partners', 'business partners'],
  },
  {
    statement: 'Non-public education information',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Inferences drawn from other personal information',
    sources: ['analytics and advertising partners', 'business partners', 'service providers'],
    businessPurposes: [
      'provide services',
      'marketing and remarketing',
      'improve customer experience',
      'improve service offerings',
      'respond to inquiries',
    ],
    toWhomDisclosedList: ['analytics and advertising partners', 'business partners'],
  },
];
