import React, { useState, useContext } from 'react';
import { StoreContext } from 'index';
import { Loader, LoginFormCopy, Training, TrayvoBadge } from 'components/Shared';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import ButtonLoader from 'react-loader-spinner';
import { Paymentpopup, CardPopup } from '../../Popup';
import {
  isFreeTraining,
  priceFormatter,
  notify,
  removeSpecificLocalStorageKeys,
} from '../../../utils/utilities';
import { CustomerDetail, AuthModal } from './index';
import { faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import './card.scss';
import { getFormattedLocalDate, getFormattedLocalTime, getTimeZone } from 'utils/helpers/timeZone';

import InvalidGradeModal from './InvalidGradeModal';
import { GradeOptions, GradeYears, YearsToGrade } from '../../../constants';
import {TrainerBadge} from "../../Shared/TrainerBadge";
const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);

const TextContainer = ({ title, value, description, bold, highlight }) => (
  <div className="flex justify-between my-1 p-2 rounded-md border" style={{ backgroundColor: highlight ? '#F5F6FA' : '#fff' }}>
    <div className='pr-2'>
      <span className={`text-sm text-primary-color block font-bold`}>{title}</span>
      {description &&
        <span style={{ fontSize: '.8rem', fontWeight: 400, marginTop: 8, lineHeight: 1 }}>
          {description}
        </span>
      }
    </div>
    <span className={`text-sm text-primary-color font-bold`}>
      {value}
    </span>
  </div>
);

const CardDetail = ({ value, exist, addCard }) => (
  <div className="w-full">

    <div className="px-1">
      <small className={`text-sm font-sf-regular capitalize`} style={{ opacity: .8 }}> Card number </small>
    </div>

    <div className={`px-1 mb-2 flex items-center border rounded-md h-8 ${!exist ? 'bg-gray-200' : 'bg-white'}`}>

      <div className="row flex w-full items-center px-1 ">
        <div className="flex-1 ">
          {!exist ? (
            <button onClick={() => addCard()} className='w-full focus:outline-none'>
              <small className="primary-text-regular text-sm  hover:text-primary-color">
                {'Add a payment method'}
              </small>
            </button>
          ) : (
            <small className={`text-sm primary-text-regular`}>
              {value}
            </small>
          )}
        </div>
      </div>
    </div>
  </div>
);

function Checkout(props) {

  const history = useHistory();
  const location = useLocation();
  const store = useContext(StoreContext);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState('');
  const [bookLoader, setBookLoader] = useState(false);
  const [showModal, setModal] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const [showAuthModal, setAuthModal] = useState(false);
  const [loginModal, showLoginModal] = useState(true);
  const [guest, setGuest] = useState(false);

  const [showInvalidGradeModal, setShowInvalidGradeModal] = useState(false);

  if (!location.state) {
    const serviceLocation = JSON.parse(localStorage.getItem('serviceLocation'));
    return serviceLocation ? history.replace(serviceLocation) : history.replace('/');
  }

  const service = location?.state?.providedService;
  const bookingDate = location?.state?.bookingDate;
  const slot = location?.state?.selectedSlot;
  const isPackage = service?.attributes?.package;

  const trainingPrice = +service.attributes.price || 0;
  const serviceFee = +service.attributes.client_service_fee || 0;
  const processingFee = +service.attributes.stripe_fee || 0;
  const totalPrice = trainingPrice + serviceFee + processingFee;

  let athlete = toJS(store.atheleteStore.athelete);
  let user = toJS(store.userStore.currentUser);
  let cards = toJS(store.paymentStore.creditCards);
  let defaultCard = toJS(store.paymentStore.defaultCard);
  let selectedLocation = toJS(store.userStore.selectedLocation);

  store.userStore.hideHeader(true);

  const submit = async e => {
    e.preventDefault();
    if (error) {
      elements.getElement('card').focus();
      return;
    }
    setLoader(true);

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result && result.token) {
      await store.paymentStore.createCard(result.token.id);
      await store.paymentStore.getCards();
      setLoader(false);
      setModal(false);
    } else {
      notify('Invalid card details');
      setLoader(false);
    }
  };

  const checkout = () => {
    if (user.data) {
      const isFree = isFreeTraining(service);
      if (!isFree && cards.length === 0) {
        notify('Add payment method');

      } else {
        setBookLoader(true);
        const booking = {
          notes: note,
          from_time: slot?.from_time,
          to_time: slot?.to_time,
          booking_date: bookingDate,
          provided_service_id: service?.id,
          location: selectedLocation?.location,
          address: selectedLocation?.address,
        }

        const cardId = isFree ? undefined : defaultCard?.attributes?.stripe_id

        store.atheleteStore
          .createBookingRequest(booking, cardId)
          .then((data) => {
            notify('You are all booked! See below for your training details.', 'success');
            store.atheleteStore.clearRequestedBookings();
            store.userStore.resetLocationAddress();
            setBookLoader(false);
            removeSpecificLocalStorageKeys(['serviceLocation']);

            if (user.data.attributes.guest_user) {
              localStorage.clear();
              store.userStore.logout();
              history.push('/');
            } else history.push('/bookings');
          })
          .catch((error) => {
            if (error === 'invalid_grade') {
              setShowInvalidGradeModal(true);

            } else if (error === 'invalid_gender') {
              notify('Looks like you have selected training that is not for your gender.');
            }
            console.log('Booking error:', error);
            setBookLoader(false);
          });
      }
    } else setAuthModal(true);
  };

  const editDetails = id => {
    if (id === 'card') setCardModal(true);
    else history.push('/profile');
  };

  const addNewCard = () => {
    setCardModal(false);
    setModal(true);
  };

  const deleteCard = async id => {
    await store.paymentStore.deleteCard(id);
    store.paymentStore.getCards();
  };

  const TrainerInfo = ({ loader, athlete }) => {
    return (!loader && athlete) ?
      <div className="hidden md:block bg-white absolute p-3 pb-8 z-10 border"
        style={{
          left: 30,
          top: 0,
          borderBottomLeftRadius: 100,
          borderBottomRightRadius: 100,
        }}>
        <TrainerBadge athlete={athlete} />
      </div>
      : ''
  }

  const goToTrainerTrainings = () => {
    const { slug_title } = service.attributes;
    const slugParts = slug_title.split('/');
    const userSlug = slugParts[0];
    return history.push('/' + userSlug);
  }

  const getGradeYear = (gradeValue) => {
    const grade = GradeYears.find(grade => grade.value === gradeValue);
    return grade.label;
  }

  const getGradeName = (gradeValue) => {
    const grade = GradeOptions.find(grade => grade.value === gradeValue);
    return grade.name;
  }

  const passingYear = user?.data?.attributes?.passing_year;
  const minGrade = service?.attributes?.min_grade;
  const maxGrade = service?.attributes?.max_grade;

  return (
    <div className="bg-white h-screen w-full p-1 overflow-x-hidden relative flex items-start justify-center">

      <TrainerInfo loader={loader} athlete={athlete} />

      {(!loader && athlete) ? (

        <div className="md:my-8 w-full md:w-9/12 xl:w-8/12 h-full">
          <div className='flex items-center justify-start w-full md:hidden my-1'>

            <button onClick={() => history.goBack()} className="ml-2 mr-4 leading-none">
              <FontAwesomeIcon icon={faChevronLeft} size={'lg'} color="#565656" />
            </button>
            <TrainerBadge athlete={athlete} mobile />
            <div className="ml-auto">
              <TrayvoBadge />
            </div>

          </div>


          <div className="w-full flex flex-col md:flex-row justify-between md:mt-6 md:border md:rounded-lg relative">

            <div className="hidden md:block md:absolute" style={{ top: '1px', right: '1px' }}><TrayvoBadge /></div>

            <div className='w-full absolute' style={{ top: 0, left: 0 }}>
              <button onClick={() => history.goBack()} className="hidden md:block m-4 leading-none focus:outline-none">
                <FontAwesomeIcon icon={faChevronLeft} size={'lg'} color="#042E60" />
              </button>
            </div>

            <div className="flex justify-center w-full h-auto md:w-1/2 my-6 md:my-4 px-2 md:p-8">
              <Training
                trainingAttributes={service.attributes}
                athlete={athlete}
                showTrainingType
                isCheckout={true}
                showFullAddress={true}
                hideDescription={false}>

                <div className="w-full flex flex-col mb-4">
                  { !isPackage &&
                    <TextContainer
                      title={getFormattedLocalDate(slot.training_start_date_time, 'MM-DD-YYYY')}
                      value={`${getFormattedLocalTime(slot.training_start_date_time)} - ${getFormattedLocalTime(slot.training_end_date_time)} (${getTimeZone()})`}
                    />
                  }
                </div>

                <div className="w-full flex flex-col mb-4">
                  { trainingPrice > 0 &&
                    <TextContainer
                      title="Training Cost (1 spot)"
                      value={priceFormatter(trainingPrice)}
                    />
                  }

                  { serviceFee > 0 &&
                    <TextContainer
                      title="Service Fee"
                      value={priceFormatter(serviceFee)}
                      description="This fee allows all college athletes to keep 100% of their training cost"
                    />
                  }

                  { processingFee > 0 &&
                    <TextContainer
                      title="Payment Processing Fee"
                      value={priceFormatter(processingFee)}
                      description="This is the standard fee for credit card payment processing"
                    />
                  }

                  <TextContainer
                    bold
                    title="Total Due (1 spot)"
                    highlight
                    value={ priceFormatter(totalPrice)}
                  />

                </div>
              </Training>
            </div>

            <div className="flex flex-col p-2 md:p-8 md:my-4 justify-start items-center w-full md:w-1/2 md:h-auto border-t md:border-0 md:border-l">
              {user.data ? (
                <div>
                  <div className="w-full flex justify-between my-4 md:my-0 md:mb-4">
                    <span className="text-primary-color font-sf-regular text-xl">
                      Player Details Review
                    </span>

                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={() => editDetails()}>
                      <div>
                        <FontAwesomeIcon
                          icon={faEdit}
                          size="xs"
                          color="#042E60"
                          className="mr-1"
                        />
                        <span className="text-primary-color hover:text-gray-500 text-right text-sm">
                          Edit
                        </span>
                      </div>
                    </button>
                  </div>

                  <p className="primary-text-regular font-sf-regular normal-case text-sm mb-2">
                    Please review the details of the player you would like to book for this training
                  </p>

                  <CustomerDetail
                    key={cards}
                    cards={cards}
                    defaultCard={defaultCard}
                    editDetails={id => editDetails(id)}
                    user={user}
                    addCard={() => { setModal(true) }}
                  />

                  {parseInt(service?.attributes?.price) ?
                    <div className="payment-detail py-4 w-full" >

                      <div className="w-full flex justify-between mb-3">
                        <span className="primary-text-regular font-sf-regular text-xl">Payment method</span>

                        <button className="focus:outline-none flex items-center" type="button" onClick={() => editDetails('card')}>
                          <FontAwesomeIcon icon={faEdit} size="sm" color="#042E60" className="mr-1" />
                          <span className="text-primary-color hover:text-gray-500 text-right text-sm">Edit</span>
                        </button>
                      </div>

                      <CardDetail
                        title={'card'}
                        value={defaultCard?.attributes?.last4}
                        editDetails={editDetails}
                        exist={cards.length > 0}
                        addCard={() => {
                          setModal(true);
                        }} />
                    </div>
                    : ''
                  }

                  <textarea
                    placeholder="Add details for trainer"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                    autoFocus
                    className=" w-full text-sm flex-1 p-2 bg-white border rounded-md focus:outline-none mt-2">
                  </textarea>

                  <button onClick={() => { !bookLoader && checkout(service?.attributes) }}
                    disabled={bookLoader}
                    className={`rounded-md border  ${bookLoader ? 'bg-gray-600' : 'bg-primary-color'} border-gray-400 text-white text-xs p-2 flex items-center justify-center font-sf-regular w-full my-4`}>
                    Book Now
                    <ButtonLoader
                      visible={bookLoader}
                      type="ThreeDots"
                      color="#fff"
                      className="ml-3"
                      height={20}
                      width={30}
                    />
                  </button>
                </div>
              ) : (
                <div className="mt-2">
                  <LoginFormCopy
                    onSuccess={() => {
                      setAuthModal(false);
                      setGuest(false);
                    }}
                    showSignup={guest => {
                      setAuthModal(true);
                      showLoginModal(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      <AuthModal
        visible={showAuthModal}
        guest={guest}
        loginModal={loginModal}
        showLoginModal={value => showLoginModal(value)}
        setAuthModal={value => {
          setAuthModal(value);
          setGuest(false);
        }}
        onSuccess={() => {
          setAuthModal(false);
          setGuest(false);
          setModal(true);
        }}
      />

      <Paymentpopup
        visible={showModal}
        setModal={value => setModal(value)}
        submit={e => submit(e)}
        loader={loader}
        setError={error => setError(error)}
      />

      <CardPopup
        isVisible={cardModal}
        cards={cards}
        loader={loader}
        addNewCard={() => addNewCard()}
        setCardModal={value => setCardModal(value)}
        deleteCard={id => deleteCard(id)}
      />
      {
        showInvalidGradeModal &&
        <InvalidGradeModal
          visible="true"
          onHide={() => setShowInvalidGradeModal(false)}
          onBack={() => goToTrainerTrainings()}
          header={'Warning'}
          body={`You are a ${YearsToGrade(passingYear)} (${passingYear}) and this training is only open to ${getGradeName(minGrade)} - ${getGradeName(maxGrade)}. To complete booking you can change your grad year in your profile settings.`}>
        </InvalidGradeModal>
      }
    </div>
  );
}

const WrappedCheckout = observer(Checkout);

const CheckoutPage = props => {
  return (
    <div className="bg-white row justify-center" fluid='_'>
      <div className="AppWrapper">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <WrappedCheckout {...props} />
        </Elements>
      </div>
    </div>
  );
};

export default observer(CheckoutPage);
