import React, { useEffect, useContext } from 'react';
import { sport, sport_light, Placeholder } from '../../../assets/img';
import ProgressiveImage from 'react-progressive-image';
import { StoreContext } from 'index';
import { TrayvoBadge } from '../../Shared';
const notSpecified = 'NOT SPECIFIED';

const SmallText = ({ text, fontSize }) => (
  <span className={`primary-text-regular pr-1`} style={{ fontSize: fontSize || 12 }}>
    {text}
  </span>
);

function SchoolBanner({ school }) {
  const store = useContext(StoreContext);

  useEffect(() => {
    store.userStore.changeHeaderColor(school.color_code ? school.color_code : null);
    return () => {
      store.userStore.changeHeaderColor(null);
    };
  }, [school]);

  return (
    <div className="w-full">
      <div className="p-4 md:p-6 w-full flex items-center justify-between flex-wrap relative bg-white">
        <div className="absolute top-0 right-0">
          <TrayvoBadge color={school.color_code ? school.color_code : null} />
        </div>

        <div className="w-full">
          <div className="flex items-center justify-center">
            <ProgressiveImage src={sport} placeholder={sport_light}>
              { src => (
                <img
                  src={school.logo_url ? school.logo_url : Placeholder}
                  className="rounded-full h-24 w-24 border-2 border-white object-cover"
                />
              ) }
            </ProgressiveImage>

            <div className="flex flex-col ml-4 mt-1">
              <SmallText text={school.nickname} fontSize={15} />
              <SmallText text={school.city + ', ' + school.state} />

              <div className="flex items-center content-center pt-2">
                {school.facebook_url && (
                  <a
                    href={school.facebook_url}
                    className="fa fa-facebook border-none"
                    target="_blank"
                  />
                )}
                {school.twitter_url && (
                  <a href={school.twitter_url} className="fa fa-twitter border-none" target="_blank" />
                )}
                {school.instagram_url && (
                  <a
                    href={school.instagram_url}
                    className="fa fa-instagram border-none"
                    target="_blank"
                  />
                )}
                {school.website_url && (
                  <a href={school.website_url} className="fa fa-link border-none" target="_blank" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolBanner;
