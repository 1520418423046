import { observer } from 'mobx-react';
import moment from 'moment';
import { formatDate } from '../../../utils/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { findWindows } from 'windows-iana';
import momentTimezone from 'moment-timezone';
import TimeSlot from '../Profile/TimeSlot';
import React from 'react';


function TimeSlotSelection(props) {
  const {
    slots, selectedDate, selectedEntry, selectedTime,
    service, requestedBookings
  } = props;
  const { onDaySelected, bookAthelete, setSelectedTime, setSelectedSlot, scrollToBottom } = props;

  const entries = selectedEntry && selectedEntry.filter(item => item.from_time).length > 0
    ? selectedEntry.filter(
      item =>
        item.from_time &&
        moment(item.localDateTime, 'YYYY-MM-DD h:mm A').isAfter(
          moment()
        )
    )
    : selectedEntry

  const selectedDateIndex = slots.findIndex((slot) => {
    return slot[0] === selectedDate;
  })

  const prevDate = selectedDateIndex > 0 ? slots[selectedDateIndex - 1] : null;
  const nextDate = selectedDateIndex < slots.length - 1 ? slots[selectedDateIndex + 1] : null;

  const changeDate = function (selectedDate) {
    onDaySelected(selectedDate[0], selectedDate[1]);
  }
  const calendarView = function () {
    onDaySelected(null);
  }


  return (
    <div className="text-center slots-navigation flex flex-col justify-center items-center max-h-screen pb-4 w-full">
      <h3 className="text-black text-center text-xl">
        Select Time
      </h3>

      <div className="mb-3">
        <p className="text-xs text-t-gray text-center">
          <FontAwesomeIcon
            icon={faMapPin}
            size="1x"
            className="primary-color-icon mr-1 fa-w-18"
          />
          {findWindows(momentTimezone.tz.guess())}
        </p>
      </div>

      <div className="mb-1 flex items-center justify-center w-11/12">
        <button disabled={!prevDate}
          className={!prevDate ? 'opacity-25 cursor-not-allowed focus:outline-none' : 'focus:outline-none'}
          onClick={() => changeDate(prevDate)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
            <g></g>
            <path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z"></path>
          </svg>
        </button>


        <div className="inline-block w-full text-center mx-4">
          <p className="regular-black text-primary-color">
            {selectedDate
              ? moment(formatDate(selectedDate)).format('dddd, MMMM Do')
              : ' - - '}
          </p>
        </div>

        <button disabled={!nextDate}
          className={!nextDate ? 'opacity-25 cursor-not-allowed focus:outline-none' : 'focus:outline-none'}
          onClick={() => changeDate(nextDate)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path>
          </svg>
        </button>
      </div>



      <TimeSlot entries={entries}
        trainingType={service.attributes.training_type}
        selectedTime={selectedTime}
        bookAthelete={bookAthelete}
        setSelectedTime={setSelectedTime}
        setSelectedSlot={setSelectedSlot}
        scrollToBottom={scrollToBottom}
        requestedBookings={requestedBookings} />
    </div>
  )
}

export default observer(TimeSlotSelection);
