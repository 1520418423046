import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from 'index';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from 'components/Shared/Loader';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Booking from './Booking';
import InfiniteScroll from 'react-infinite-scroller';
import ScrollUpButton from 'react-scroll-up-button';

const NoBookingsText = () => (
  <h3 className="text-center text-primary-color mt-10">No Bookings Available</h3>
);

function Bookings(props) {
  const store = useContext(StoreContext);
  store.userStore.hideHeader(false);

  const [loader, setLoader] = useState(false);
  const [tab, setTab] = useState('confirmed');
  const history = useHistory();
  const [cancelLoader, setCancelLoader] = useState(false);
  const [selectedCancelId, setSelectedCancel] = useState(null);

  useEffect(() => {
    getAllBookings();
  }, []);

  const getAllBookings = async () => {
    setLoader(true);
    await store.userStore.getBookings();
    setLoader(false);
  };

  const loadMore = async () => {
    let bookingsPagy = toJS(store.userStore.bookingsPagy);

    if (bookingsPagy && bookingsPagy.next) {
      await store.userStore.getBookings(bookingsPagy.next);
    }
  };

  const getRejectedBookings = async () => {
    setLoader(true);
    await store.userStore.getRejectedBookings();
    setLoader(false);
  };

  const loadMoreRejected = async () => {
    let rejectedBookingsPagy = toJS(store.userStore.rejectedBookingsPagy);

    if (rejectedBookingsPagy && rejectedBookingsPagy.next) {
      await store.userStore.getRejectedBookings(rejectedBookingsPagy.next);
    }
  };

  const cancelBooking = async id => {
    setSelectedCancel(id);
    setCancelLoader(true);
    await store.userStore.cancelBooking(id);
    setCancelLoader(false);
  };

  const bookings = toJS(store.userStore.bookings)
      .filter(item => item.athlete !== null && item.status !== 'rejected');
  const rejectedBookings = toJS(store.userStore.rejectedBookings)
      .filter(item => item.status === 'rejected' && item.athlete.data !== null);

  // rejectedBookings = bookings;

  const bookingsPagy = toJS(store.userStore.bookingsPagy);
  const rejectedBookingsPagy = toJS(store.userStore.rejectedBookingsPagy);

  return (
    <div className="bg-main-background min-h-screen p-5 pt-24">
      <div className="flex py-4 flex-col">
        <div className="w-full md:w-9/12 flex flex-col items-center self-center">
          <div
            style={{ padding: '1px' }}
            className="flex flex-wrap mb-4 rounded-lg shadow-md bg-white border-gray-500 border">
            <div className="w-full md:w-1/2 text-center border-r-2 border-dotted">
              <button
                onClick={() => {
                  setTab('confirmed');
                }}
                style={{ fontSize: 11, marginRight: '2px' }}
                className={` text-primary-color text-xs p-3 w-56 font-normal my-2 md:my-0 focus:outline-none ${tab === 'confirmed' && 'border-b-2 border-primary-color'
                  }`}>
                Confirmed/Cancelled Bookings
              </button>
            </div>

            <div className="w-full md:w-1/2 text-center">
              <button
                onClick={() => {
                  setTab('rejected');
                  rejectedBookings.length === 0 && getRejectedBookings();
                }}
                style={{ fontSize: 11 }}
                className={`text-primary-color text-xs p-3 w-56 font-normal my-2 md:my-0 focus:outline-none ${tab === 'rejected' && 'border-b-2 border-primary-color'
                  }`}>
                Rejected Bookings
              </button>
            </div>
          </div>

          {!loader ? (
            <div className="w-full">
              {tab === 'rejected' ? (
                <div className="w-full">
                  { rejectedBookings.length > 0 ? (
                    <div className="w-full">
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMoreRejected}
                        loader={
                          <div className="loader" key={0}>
                            Loading ...
                          </div>
                        }
                        hasMore={rejectedBookingsPagy && rejectedBookingsPagy.next}>
                        <div
                          style={{ borderRadius: 10 }}
                          className="flex w-full bg-white px-3 shadow-md flex-wrap justify-between">
                          { rejectedBookings.map((item, index) => (
                              <Booking
                                key={index}
                                booking={item}
                                index={index}
                                history={history}
                                cancelLoader={cancelLoader}
                                selectedCancelId={selectedCancelId}
                                cancelBooking={id => cancelBooking(id)}
                              />
                            )) }
                        </div>
                      </InfiniteScroll>
                    </div>
                  ) : (
                    <div>
                      <NoBookingsText />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {bookings.length > 0 ?
                    (
                      <div className="w-full">
                        <InfiniteScroll
                          pageStart={1}
                          loadMore={loadMore}
                          loader={
                            <div className="loader" key={0}>
                              Loading ...
                            </div>
                          }
                          hasMore={bookingsPagy && bookingsPagy.next}>
                          <div
                            style={{ borderRadius: 10 }}
                            className="flex w-full bg-white px-3 shadow-md flex-wrap justify-between">
                            { bookings.map((item, index) => (
                                <Booking
                                  key={index}
                                  booking={item}
                                  index={index}
                                  history={history}
                                  cancelLoader={cancelLoader}
                                  selectedCancelId={selectedCancelId}
                                  cancelBooking={id => cancelBooking(id)} />
                              ))
                            }
                          </div>
                        </InfiniteScroll>
                      </div>
                    ) : (
                      <div>
                        <NoBookingsText />
                      </div>
                    )}
                </div>
              )}
            </div>
          ) : (
            <div className="loader-container">
              <Loader />
            </div>
          )}
        </div>
      </div>
      <ScrollUpButton />
    </div>
  );
}

export default observer(Bookings);
