import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFreeTraining, priceFormatter } from '../../utils/utilities';
import { faLock, faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { FORCE_NOTIFY_TRAININGS, shortDays } from "../../constants";
import { getFormattedLocalDate } from "../../utils/helpers/timeZone";

const GenderMap = {
  'female': 'Girls',
  'male': 'Boys',
  'unisex': 'Girls, Boys'
}

const getColorByTrainigType = (trainingType) => {
  const color = 'rgb(5,46,96)'
  return color;
}

const getColorByTrainigTypeOld = (trainingType) => {
  return trainingType === 'virtual'
    ? '#d7a9e3'
    : trainingType === 'individual'
      ? '#8bbee8'
      : '#a8d5ba';
}

const hasAvailability = (id, status, athlete) => {
  const included_trainings = (athlete && athlete.included_trainings) || [];
  return included_trainings.includes(parseInt(id)) && status === 'published';
};

const canBookPackage = (training) => {
  if (training.package) {
    const end = new Date(training.package_end.substring(0, 10)).getTime();
    // const end = new Date('2022-08-30').getTime();
    const now = new Date().getTime();

    let hasSpots = training.available_spots > 0;
    if (training.training_type === 'individual') {
      // Individual trainigs dont have spots,
      // they are just one big spot so...
      hasSpots = true;
    }

    return now < end && hasSpots;
  }

  return false;
}

function Training({
  children,
  trainingAttributes,
  athlete,
  notifyAction,
  bookAction,
  showButtons,
  showTrainingType,
  hideDescription,
  fullHeight,
  showFullAddress,
  isCheckout
}) {

  const {
    id,
    privacy_setting,
    name,
    days, slots,
    image_url,
    duration,
    provided_service_locations,
    training_type,
    price,
    discription,
    all_slots_booked,
    slug,
    status,
    gender,
    min_grade,
    max_grade,
    max_seats,
    available_spots,
    package_start,
    package_end,
    sessions_count
  } = trainingAttributes;

  const isPackage = trainingAttributes.package;

  const store = useContext(StoreContext);
  let selectedLocation = toJS(store.userStore.selectedLocation);

  // showFullAddress ? address : city_and_state
  const getTrainingAddresses = (selectedLocation, providedServiceLocations, showFullAddress) => {
    if (selectedLocation) {
      return showFullAddress ? selectedLocation?.address : selectedLocation.city_and_state;
    };

    return providedServiceLocations.data
      .slice(0, 3)
      .map(({ attributes: { address, city_and_state } }) => showFullAddress ? address : city_and_state)
      .join(' or ');
  }


  const getTrainingLocations = (selectedLocation, providedServiceLocations) => {
    if (selectedLocation) {
      return selectedLocation?.location;
    };

    return providedServiceLocations.data
      .slice(0, 3)
      .map(({ attributes: { location } }) => location)
      .join(' or ');
  }

  const genderAndGrade = [gender ? GenderMap[gender] : GenderMap['unisex']];
  if (min_grade && max_grade) {
    if (min_grade === max_grade) {
      genderAndGrade.push('Grade ' + min_grade);

    } else {
      genderAndGrade.push('Grades ' + min_grade + ' - ' + max_grade);
    }
  } else {
    genderAndGrade.push('All Ages');
  }

  const canBeBooked =
    FORCE_NOTIFY_TRAININGS.includes(id) === false &&
    (
      canBookPackage(trainingAttributes) || hasAvailability(id, status, athlete)
    );
  const onButtonClicked = (e) => {
    e.stopPropagation();
    if (canBeBooked) {
      bookAction({
        id,
        attributes: {
          ...trainingAttributes,
          privacy_setting,
          name,
          image_url,
          duration,
          provided_service_locations,
          training_type,
          price,
          discription,
          all_slots_booked,
          slug,
        },
      })
    } else {
      notifyAction(id, name);
    }
  }

  let datesInformation;
  if (isPackage) {
    const start = getFormattedLocalDate(package_start.substring(0, 10), 'M/D');
    const end = getFormattedLocalDate(package_end.substring(0, 10), 'M/D');
    datesInformation = `${start} - ${end}`;

  } else {
    const { last_slot: lastSlot, next_slot: nextSlot } = slots;
    if (lastSlot && nextSlot) {
      let availabilityRange;
      if (lastSlot.id === nextSlot.id) {
        availabilityRange = getFormattedLocalDate(nextSlot.booking_date, 'M/D');
        const day = new Date(nextSlot.booking_date).getDay();
        const availabilityDays = shortDays[day];
        datesInformation = `${availabilityRange} (${availabilityDays})`;

      } else {
        const next = getFormattedLocalDate(nextSlot.booking_date, 'M/D');
        const last = getFormattedLocalDate(lastSlot.booking_date, 'M/D');
        availabilityRange = next + ' - ' + last;
        const availabilityDays = days.length < 7 ? days.map((d) => shortDays[d]).join(', ') : 'All days';
        datesInformation = `${availabilityRange} (${availabilityDays})`;
      }
    }
  }


  return (
    <div className={`flex flex-col w-full ${fullHeight ? 'md:h-full' : 'md:h-auto'} items-center p-0 m-0 border border-stone-800 bg-white rounded-lg relative overflow-hidden`}>
      {showTrainingType &&
        <div className="flex items-center justify-center w-full p-1 md:p-0 "
          style={{
            // borderColor: getColorByTrainigType(training_type),
            backgroundColor: 'rgba(5,46,96, 0.05)'
          }}>

          <FontAwesomeIcon
            icon={faCircleSolid}
            color={getColorByTrainigTypeOld(training_type)}
            className="mr-1"
            style={{ fontSize: 12 }}
          />

          <span className="capitalize font-sf-regular font-normal md:text-sm leading-1"
            style={{
              // color: getColorByTrainigTypeOld(training_type),
              color: 'rgb(124, 130, 141)'
            }}>
            {training_type}
          </span>
        </div>
      }

      <div className={`flex flex-col items-center w-full ${fullHeight ? 'md:h-full' : 'md:h-auto'}  px-2 md:px-4 my-5 overflow-hidden`}>
        {isPackage &&
          <div className="absolute right-0 top-0 left-auto overflow-hidden" style={{ transform: 'rotate(90deg)', width: 160, height: 160 }}>
            <div className="capitalize font-sf-regular font-normal md:text-sm p-1 text-center border-2 border-solid"
              style={{
                backgroundColor: '#fff',
                color: '#9b9b9b',
                borderColor: '#eee',
                transform: 'translateY(-50%) translateX(-50%) translateX(32px) translateY(32px) rotate(-45deg)'
              }}>package
            </div>
          </div>}

        <div className="flex text-center leading-tight">
          {privacy_setting === 'private' && (
            <FontAwesomeIcon
              icon={faLock}
              size="lg"
              color="#565656"
              className="mr-1 p-1"
            />
          )}
          {name?.length &&
            <span className="uppercase font-sf-regular font-medium tracking-wilder"
              style={{
                color: 'rgb(36, 36, 36)',
                // fontSize: '15px'
              }}>
              {name}
            </span>}
        </div>

        <div className="text-center text-sm font-sf-regular font-normal" style={{ color: 'rgb(104, 104, 104)' }}>
          {/* <span style={{ color: 'rgb(104, 104, 104)' }}>
              {parseInt(duration)} mins {provided_service_locations.data.length ? ' | ' : ''}
            </span> */}

          {training_type === 'virtual'
            ? <span>Online</span>
            : <span> {getTrainingAddresses(selectedLocation, provided_service_locations, showFullAddress)} </span>
          }
        </div>


        {/* <div className="mt-2">
            <span className="text-xl capitalize font-medium" style={{
              color: getColorByTrainigType(training_type),
            }}> {training_type === 'virtual' ? 'virtual' : training_type === 'individual' ? 'individual' : 'group'} </span>
          </div> */}
        {
          <div className='w-full my-3 px-4' style={{ color: 'rgb(5, 46, 96)' }}>

            {datesInformation &&
              <p className='flex items-center justify-start font-sf-regular font-normal' style={{ fontSize: '15px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="xs"
                  color={'rgb(104, 104, 104)'}
                  className="mr-2"
                  style={{ fontSize: '9px' }}
                />
                <span>
                  {datesInformation}
                </span>
              </p>
            }

            <p className='flex items-center justify-start font-sf-regular font-normal' style={{ fontSize: '15px' }}>
              <FontAwesomeIcon
                icon={faCircle}
                size="xs"
                color={'rgb(104, 104, 104)'}
                className="mr-2"
                style={{ fontSize: '9px' }}
              />
              <span>
                {isFreeTraining(trainingAttributes) ? 'Free' : priceFormatter(price)}
                { isPackage ? ' for ' + sessions_count + ' sessions' : '' }
              </span>
            </p>

            <p className='flex items-center justify-start  font-sf-regular font-normal' style={{ fontSize: '15px' }}>
              <FontAwesomeIcon
                icon={faCircle}
                size="xs"
                color={'rgb(104, 104, 104)'}
                className="mr-2"
                style={{ fontSize: '9px' }}
              />
              <span>
                {parseInt(duration) || 0} Mins
              </span>
              {(training_type === 'group' || training_type === 'virtual') && max_seats > 0 &&
                <span className='ml-1'>
                  { false /* isPackage && isCheckout */
                    ? max_seats === 1 ? '(One Spot)' : `(Open spots: ${available_spots}/${max_seats})`
                    : max_seats === 1 ? '(One Spot)' : `(${max_seats} Spots Total)`
                  }
                </span>
              }
            </p>

            {training_type === 'virtual' &&
              <p className='flex items-center justify-start font-sf-regular font-normal' style={{ fontSize: '15px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="xs"
                  color={'rgb(104, 104, 104)'}
                  className="mr-2"
                  style={{ fontSize: '9px' }}
                />
                <span>
                  Zoom or Google Video Call
                </span>
              </p>
            }

            {training_type !== 'virtual' && (provided_service_locations?.data?.length || selectedLocation?.location) ?
              <p className='flex items-baseline font-sf-regular font-normal' style={{ fontSize: '15px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="xs"
                  color={'rgb(104, 104, 104)'}
                  className="mr-2"
                  style={{ fontSize: '9px' }}
                />
                {getTrainingLocations(selectedLocation, provided_service_locations)}
              </p>
              : ''
            }

            {
              <p className='flex items-center justify-start font-sf-regular font-normal' style={{ fontSize: '15px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="xs"
                  color={'rgb(104, 104, 104)'}
                  className="mr-2"
                  style={{ fontSize: '9px' }}
                />
                <span>
                  {genderAndGrade.join(' | ')}
                </span>
              </p>
            }
          </div>
        }

        {discription?.length &&
          <div className={`mb-3 text-left w-full p-1 ${hideDescription ? 'hidden md:block' : ''}`}>
            <span className="text-t-gray mt-2 text-sm font-sf-regular normal-case"
              style={{ color: '#565656' }}>
              {discription.slice(0, 260)}
            </span>
          </div>
        }

        {children}

        {/*{!all_slots_booked && canBeBooked && showButtons ? (*/}
        {canBeBooked && showButtons ? (
          <div className="flex h-full items-end w-full">
            <button
              onClick={onButtonClicked}
              className={`small-button hover:opacity-100 w-full text-white}`}
              style={{
                backgroundColor: getColorByTrainigType(training_type),
                color: '#fff'
              }}>
              Book Now
            </button>
          </div>
        ) : (showButtons &&
          <div className="flex h-full items-end w-full">
            <button
              disabled={true}
              className="small-button opacity-50 cursor-not-allowed mr-1 w-full"
              style={{
                backgroundColor: '#e8f4f9',
                color: 'rgb(5,46,96)'
              }}>

              Sold Out
            </button>

            <button
              onClick={e => {
                e.stopPropagation();
                notifyAction(id, name);
              }}
              className="small-button opacity-75 hover:opacity-100 w-full"
              style={{
                backgroundColor: '#e8f4f9',
                color: 'rgb(5,46,96)'
              }}>

              Get Notified
            </button>
          </div>
        )}
      </div>

    </div>
  );
}

export default Training;
