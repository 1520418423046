export { default as BgHome } from './BgHome.png';
export { default as Tiktok } from './tiktok.png';
export { default as profileIcon } from './profile-icon.png';
export { default as deleteIcon } from './delete.png';
export { default as sport } from './sports.jpeg';
export { default as logoWhite } from './logoWhite.png';
export { default as profileIcon_light } from './profile-icon_tn.jpg';
export { default as sport_light } from './sports.jpeg';
export { default as bgLoginSignupLight } from './bg-login-signup_tn.jpg';
export { default as bgLoginSignupBlur } from './bg-login-signup-blur.png';
export { default as Placeholder } from './image_placeholder.jpg';
export { default as LogoRound } from './logoRound.png';
export { default as LogoWhite_light } from './logoWhite_tn.jpg';
export { default as SuccessTick } from './success-tick.png';
export { default as TrayvoBlue } from './trayvoBlue.png';
export { default as TrayvoTrainingLogo } from './trayvoTrainingLogo.png'
export { default as DefaultAvatar } from './DefaultAvatar.png'
