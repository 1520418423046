export default {
  signUp: () => `/v1/client/profiles`,
  signIn: () => `/v1/client/sessions`,
  profile: () => `/v1/client/profiles`,
  createGuest: () => `/v1/client/profiles/create_guest`,
  profileSignedUrl: () => `/v1/client/profiles/signed_url?file_type=jpg`,

  //bookings
  bookings: () => `/v1/service_professional/bookings`,
  book: () => `/v1/client/bookings`,
  bookingRequest: () => `/v1/client/bookings`,
  bookingRequestEntry: () => `/v1/client/booking_request_entries`,
  changeRequestedBookingStatus: id => `/v1/client/booking_requests/${id}/request_booking`,

  //availabilities
  availabilities: () => `/v1/service_professional/availabilities`,
  entries: () => `/v1/client/calendar_entries`,

  //connect-account
  account: () => `/v1/service_professional/profiles/connect_account`,

  //atheletes
  atheletes: () => `/v1/client/athletes`,

  //checkout
  checkout: id => `/v1/client/payments/${id}/checkout`,

  //credit cards
  cards: () => `/v1/client/cards`,
  cardDelete: id => `/v1/client/cards/${id}`,

  //charge
  createCharge: id => `/v1/client/payments/${id}/create_charge/`,
  transferCharge: id => `/v1/payments/${id}/transfer_charge/`,

  highSchool: () => `/v1/high_schools`,
  getColleges: () => `/v1/colleges`,
  sports: () => `/v1/sports`,

  //reviews
  submitReview: () => `/v1/client/reviews`,

  //subscribed provided service notifications
  subscribedTraining: () => `/v1/client/subscribed_provided_services`,

  college: () => `/v1/colleges`,
  college_schedules: (slug) => `/v1/client/colleges/${slug}`,

  groups: () => `v2/athlete/groups`,

  //reset password
  sendResetPassword: () => `/v1/client/profiles/send_reset_password`,
  updatePassword: () => `/v1/client/profiles/update_password`,
  resetPassword: () => `/v1/client/profiles/reset_password`,
};
