import React from 'react';

const InvalidGradeModal = props => {
  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-50`
          : 'hidden'
      }>
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>

      <div className="rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div className="p-5 bg-main-background">
          <div className='modal-header w-full text-center text-lg'>
            {props.header}
           </div>
          <div className='modal-body my-5'>
            {props.body}
          </div>
          <div className='modal-footer flex items-center justify-center'>
            {/* <button className='primary-button w-32' onClick={() => props.onHide()}>
              Close
            </button> */}
            <button className='primary-dark-button w-32' onClick={() => props.onBack()}>
              Back to booking page
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default InvalidGradeModal;
