import React, { useState, useEffect, useContext, useLayoutEffect, useMemo } from 'react';
import { StoreContext } from 'index';

import Loader from 'components/Shared/Loader';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { NotFound } from '../NotFound';
import SelectLocationModal from './SelectLocationModal';
import AuthModal from '../Checkout/AuthModal';
import { NotifyEmail, NotificationsEnabled } from '../../Popup';
import { TrainerBanner, Training } from '../../Shared';
import {DISABLED_TRAININGS} from "../../../constants";

function TrainerProfile(props) {
  const [loader, setLoader] = useState(false);
  const [focusedService, setFocusedService] = useState(null);
  const [showSelectModal, setSelectModal] = useState(null);
  const [selectedLocation, setLocation] = useState(null);
  const [selectedAddress, setAddress] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showAuthModal, setAuthModal] = useState(false);
  const [loginModal, showLoginModal] = useState(true);
  const [guest, setGuest] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceName, setSelectedServiceName] = useState('');

  const history = useHistory();
  const store = useContext(StoreContext);

  let user = toJS(store.userStore.currentUser);
  let athelete = toJS(store.atheleteStore.athelete);

  let {
    match: { params },
  } = props;

  useEffect(() => {
    store.userStore.hideHeader(true);
    store.userStore.resetLocationAddress();
    store.atheleteStore.getAthelete(params.athleteSlug)
      .then(res => {
        setLoader(false);
      });

    return () => {
      store.userStore.hideHeader(null);
    };
  }, []);

  useLayoutEffect(() => {
    setLoader(true);
  }, []);

  const servicePressed = service => {
    setFocusedService(service);

    if (
      service.attributes.training_type !== 'virtual' &&
      !selectedLocation &&
      service.attributes.provided_service_locations.data.length > 1
    ) {
      setSelectModal(true);

    } else {
      if (service.attributes.provided_service_locations.data.length === 1) {
        store.userStore.setLocationAddress(
          service.attributes.provided_service_locations.data[0].attributes.location,
          service.attributes.provided_service_locations.data[0].attributes.address,
          service.id
        );
      } else {
        store.userStore.setLocationAddress(selectedLocation, selectedAddress, service.id);
      }

      toBookingFlow(service);
    }
  };

  // useMemo(() => {
  //   if (focusedService) servicePressed(focusedService);
  // }, [selectedLocation]);

  const toBookingFlow = (service) => {
    if (service.attributes.package) {
      const straightToCheckout = `/bookings/checkout`;
      history.push({
        pathname: straightToCheckout,
        state: {
          providedService: service,
        },
      });

    } else {
      const nonPackagePath = `/${athelete.slug}/${service.attributes.slug.split('-')[1]}`;
      history.push({
        pathname: nonPackagePath,
      });

    }
  }

  const onLocationSelected = (location, address) => {
    setAddress(address);
    setLocation(location);
    toBookingFlow(focusedService);
  }

  const notifyMe = async (id, name) => {
    setSelectedServiceName(name);
    setSelectedService(id);
    if (user && user.data && user.data.id) {
      sendNotificationCredentials(
        id,
        user.data.attributes.email,
        user.data.attributes.contact_number,
        user.data.attributes.first_name + ' ' + user.data.attributes.last_name
      );
    } else setNotifyEmail(true);
  };

  const sendNotificationCredentials = (providedServiceId, email, contactNumber, name) => {
    setNotifyEmail(false);
    let payload = {
      subscribed_provided_service: {
        contact_number: contactNumber,
        email: email,
        provided_service_id: providedServiceId,
        name,
      },
    };
    store.userStore.enableNotifications(payload).then(response => {
      if (response.status === 201) setSuccess(true);
    });
  };

  // const viewFullSchedule = () => {
  //   history.push(`/profile/schedule/${athelete.id}`);
  // };

  const services = athelete?.provided_services?.data?.length
    ? athelete.provided_services.data
        .filter(item => item.attributes.privacy_setting === 'public')
        .filter((training) => DISABLED_TRAININGS.includes(training.attributes.id) === false)
    : [];


  return (
    <div className="min-h-screen w-full p-1 overflow-x-hidden">
      {!loader && athelete ? (
        <div className="flex flex-col content-center items-center mx-auto my-4 px-2 sm:w-full md:w-5/6 xl:w-2/3">

          <TrainerBanner athlete={athelete} />

          <div className="trainings-container rounded-md w-full my-5 grid grid-cols-1 md:grid-cols-3 gap-5">
            {services?.length ? (
              services.map(({ attributes }, index) => {
                return (
                  <div className='w-full' key={index}>
                    <Training
                      trainingAttributes={attributes}
                      athlete={athelete}
                      notifyAction={notifyMe}
                      bookAction={servicePressed}
                      showButtons={true}
                      showTrainingType={true}
                      showFullAddres={false}
                      fullHeight
                    />
                  </div>
                );
              })
            ) : (
              <div fluid>
                {loader ? (
                  <Loader />
                ) : (
                  <h3 className="text-center text-black text-sm">
                    No Trainings Available.
                  </h3>
                )}
              </div>
            )}
          </div>

          {/*<button*/}
          {/*    onClick={viewFullSchedule}*/}
          {/*    className="training-cta w-48 self-center bg-transparent border-2 border-gray-400 shadow-md my-4 capitalize">*/}
          {/*    View full schedule*/}
          {/*</button>*/}

          <div className="flex justify-center font-sf-regular w-full bottom-0 left-0" style={{ bottom: '18px' }}>
            <Link className="bg-white p-3 rounded-lg text-gray-700"
              style={{ fontSize: 13 }}
              to={{ pathname: "mailto:train@trayvo.com" }}
              target='_blank'>
              Need Help? <span className='text-primary-color'>Contact us</span>
            </Link>
          </div>

        </div>
      ) : (
        <div>
          {!loader && !athelete ? (
            <NotFound />
          ) : (
            <div className="loader-container">
              <Loader />
            </div>
          )}
        </div>
      )}

      {focusedService?.attributes?.provided_service_locations?.data?.length && showSelectModal && (
        <SelectLocationModal
          service={focusedService}
          visible={showSelectModal}
          locations={
            focusedService.attributes.provided_service_locations
              .data
          }
          selectedLocation={selectedLocation}
          selectedAddress={selectedAddress}
          setSelectModal={value => {
            setSelectModal(value);
          }}
          setLocationChoice={onLocationSelected}
        />
      )}

      <AuthModal
        visible={showAuthModal}
        guest={guest}
        loginModal={loginModal}
        showLoginModal={value => showLoginModal(value)}
        setAuthModal={value => {
          setAuthModal(value);
          setGuest(false);
        }}
        onSuccess={() => {
          setAuthModal(false);
          setGuest(false);
        }}
      />

      <NotificationsEnabled
        show={success}
        serviceName={serviceName}
        athlete={athelete}
        close={() => {
          setSuccess(false);
        }}
      />

      {notifyEmail && (
        <NotifyEmail
          serviceName={serviceName}
          athlete={athelete}
          show={notifyEmail}
          onConfirm={(email, contactNumber, name) => {
            sendNotificationCredentials(selectedService, email, contactNumber, name);
          }}
          disableNotify={() => setNotifyEmail(false)}
        />
      )}
    </div>
  );
}

export default observer(TrainerProfile);
