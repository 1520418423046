import React from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { removeArrowInSelect, removeSeparator } from '../../constants';

function GooglePlacesInput({ setData, value, setValue, placeholder }) {
  const onChangeInput = val => {
    if (setValue) {
      setValue(val);
    }
    geocodeByPlaceId(val.value.place_id).then(result => {
      var address = result[0].address_components;
      var city = '',
        state = '',
        zip = '',
        route = '',
        streetNum = '',
        street = '';

      address &&
        address.forEach(function (component) {
          var types = component.types;

          if (types.indexOf('route') > -1) {
            route = component.long_name;
          }

          if (types.indexOf('street_number') > -1) {
            streetNum = component.long_name;
          }

          if (types.indexOf('locality') > -1) {
            city = component.long_name;
          }

          if (types.indexOf('administrative_area_level_1') > -1) {
            state = component.short_name;
          }

          if (types.indexOf('postal_code') > -1) {
            zip = component.long_name;
          }
        });

      street = [streetNum, route].join(' ');
      let data = { city, state, zip, street };
      setData(data);
    });
  };

  return (
    <GooglePlacesAutocomplete
      autocompletionRequest={{
        componentRestrictions: {
          country: ['us'],
        },
      }}
      selectProps={{
        value,
        onChange: onChangeInput,
        className: 'form-control text-xs font-sf-regular',
        styles: removeArrowInSelect,
        placeholder: placeholder || 'Enter City',
        components: removeSeparator,
      }}
      apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
    />
  );
}

export default GooglePlacesInput;
