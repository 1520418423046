import React from 'react';
import { ModalBackground } from '../../Shared';

const SelectLocationModal = props => {
  const onChangeValue = event => {
    let split = event.target.value.split('|');
    props.setLocationChoice(split[0].trim(), split[1].trim());
    props.setSelectModal(false);
  };

  const closeModal = () => {
    props.setSelectModal(false);
  };

  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        style={{ width: '400px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6">
          <div className="flex flex-col w-full" onChange={onChangeValue}>
            <span className="mb-5 text-center regular-black">Select Training Location</span>

            {props.locations.map((item, index) => (
              <div key={index} className="flex items-center text-sm my-2 font-sf-regular">
                <input
                  type="radio"
                  checked={
                    props.selectedLocation === item.attributes.location &&
                    props.selectedAddress === item.attributes.address
                  }
                  onChange={() => {}}
                  className="text-black mr-2 h-4 w-4 cursor-pointer"
                  value={`${item.attributes.location} | ${item.attributes.address}`}
                  name="location"
                />

                {`${item.attributes.location}  |  ${item.attributes.address}`}
              </div>
            ))}

            <button
              onClick={() => {
                closeModal();
              }}
              style={{ width: '150px' }}
              className="primary-dark-button self-center mt-6">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLocationModal;
