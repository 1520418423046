import React from 'react';
import { getAthleteName, getAthleteImageSrc } from '../../../utils/utilities';
import { TrainingBadge } from '../../Shared';
import ButtonLoader from 'react-loader-spinner';
import { getFormattedLocalDate, getFormattedLocalTime } from 'utils/helpers/timeZone';
import ToolTip from "./ToolTip";
import ContactTrainer from "../../Shared/ContactTrainer";
import { Link } from 'react-router-dom';

const Booking = ({ booking, history, index, cancelLoader, cancelBooking, selectedCancelId }) => {
  const contactTrainerTooltipText = "Reach out to your trainer to discuss details of session or to cancel the session.";
  const contactTrainerTooltipHeading = "Contact trainer to discuss";

  const isPackage = booking?.package;
  const { status } = booking;

  return (
    <div className="bg-white py-2 px-2 w-full flex flex-col md:flex-row my-2 border-b-2 relative">

      <div className='flex flex-row absolute'
        style={{
          top: index === 0 ? -7 : 0,
          right: -12,
        }}>
          { isPackage && <div className='border-b border-l border-r text-xs py-1 px-4 font-medium capitalize mx-2 bg-primary-color text-white'>
            package
          </div>
          }
        <div
          style={{
            borderTopRightRadius: index === 0 && 7,
            width: '100px',
          }}
          className={`overflow-hidden items-center justify-center flex  ${status === 'pending'
              ? 'bg-blue-500 text-primary-color'
              : status === 'accepted' || status === 'completed'
                ? 'bg-sharp-green text-white'
                : 'bg-red-800 text-white'
            } w-full text-xs py-1 font-normal capitalize`}>
          {status === 'pending' || status === 'incomplete'
            ? 'Upcoming'
            : status}
        </div>
      </div>


      <div className="flex flex-col md:flex-row flex-wrap w-full md:w-1/3 items-center md:items-start">

        {/* {console.log('Booking ---> ', booking.trainer_slug)} */}
        <Link className='sm:ml-0 md:ml-4 xl:ml-0' to={{ pathname: booking.trainer_slug}}>
          {getAthleteImageSrc(booking.athlete)}
        </Link>

        <div className="flex flex-col items-center md:items-start ml-4">
          <span style={{ fontSize: 17 }} className="font-sf-regular capitalize text-black">
            {getAthleteName(booking.athlete)}
          </span>

          <small className="opacity-75 primary-text-regular pb-4">
            {booking.provided_service.name}
          </small>

          {/* <small className="opacity-75 primary-text-regular">
            {booking.athlete.contact_number}
          </small> */}

          <TrainingBadge
            trainingType={booking.provided_service.training_type}
            small
          />
        </div>


      </div>

      <div className="flex flex-col items-center md:items-start w-full md:w-1/3 border-dotted border-l-2 text-t-gray justify-center pl-5 mb-1 border-gray-700">

        <div>
          <small className="primary-text-regular text-t-gray">
            {booking.provided_service.training_type !== 'virtual'
              ? `${booking.location} ${booking.address}`
              : 'Virtual Training'}
          </small>
        </div>

        <div>
          <small className="text-xs">
            {getFormattedLocalDate(booking?.training_start_date_time)}
          </small>
          {
            booking.package &&
            <small className="text-xs ml-1" title="Date of the last training from package">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                style={{ display: 'inline' }}
                className="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path
                  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </small>
          }

          {!booking.package &&
            <small className="ml-3 text-xs">
              {getFormattedLocalTime(booking?.training_start_date_time)}
              {' to '}
              {getFormattedLocalTime(booking?.training_end_date_time)}
            </small>
          }
        </div>
      </div>

      <div className="flex flex-col w-full md:w-1/3 md:flex-row items-center md:justify-end md:ml-4">
        <button
          onClick={() => {
            history.push(`/bookings/${booking.id}`);
          }}
          className="primary-button w-full md:w-32 mr-0 md:mr-3">
          Details
        </button>

        {(booking.status === 'incomplete' && booking.allowed_cancel) && (
          <button
            disabled={cancelLoader}
            onClick={() => cancelBooking(booking.id)}
            className="primary-button w-full md:w-32 mr-0 md:mr-3">
            Cancel
            <ButtonLoader
              visible={cancelLoader && selectedCancelId === booking.id}
              type="ThreeDots"
              color="#042E60"
              className="ml-1"
              height={20}
              width={20}
            />
          </button>
        )}

        {(booking.status === 'incomplete' && !booking.allowed_cancel) && (
          <ToolTip tooltipHeading={contactTrainerTooltipHeading} tooltipText={contactTrainerTooltipText}>
            <ContactTrainer />
          </ToolTip>
        )}
      </div>
    </div>
  );
};

export default Booking;
