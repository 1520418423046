import React, { useState, useContext, useEffect, useReducer } from 'react';
import Loader from './Loader';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { loginDescription, newAccountSubHead } from '../../stringConstants';
import PrivacyText from './PrivacyText';

const OrLine = () => (
  <div className="flex w-full justify-between my-8 items-center">
    <div style={{ height: 1, backgroundColor: '#6D799A' }} className="flex w-full mr-1" />
    <span className="text-sm text-t-gray font-sf-regular">Or</span>
    <div style={{ height: 1, backgroundColor: '#6D799A' }} className="flex w-full ml-1" />
  </div>
);

const SectionHeading = ({ heading, subheading }) => {
  return (
    <div className="flex flex-col">
      <span className="text-lg text-center text-primary-color font-sf-light">{heading}</span>
      <small className="text-primary-color font-sf-regular text-xs">{subheading}</small>
    </div>
  );
};

function LoginForm(props) {
  const history = useHistory();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(false);
  let { onSuccess } = props;
  const initialState = {
    email: '',
    password: '',
  };

  function reducer(state, action) {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    store.userStore.getSports();
  }, []);

  const submitSignin = async e => {
    e.preventDefault();
    setLoader(true);
    store.userStore
      .signIn({ email: provider.email, password: provider.password })
      .then(async result => {
        setLoader(false);
        if (result.data && result.data.attributes) {
          await store.paymentStore.getCards();

          if (onSuccess) onSuccess();
          else history.push('/bookings');
        }
      });
  };

  return (
    <div className={`bg-white ml-auto mr-auto rounded-lg max-h-screen ${onSuccess ? 'bg-main-background' : 'bg-white'}`}>
      <div className="flex-row d-flex justify-center">
        <div className={`${onSuccess ? 'px-0 items-center flex flex-col' : 'px-10'}`}>
          {!onSuccess ? (
            <div className="flex flex-col justify-center pt-6 w-full">
              <small
                style={{ fontSize: '22px' }}
                className="font-sf-regular text-primary-color text-center">
                Scheduling to make your training better
              </small>
            </div>
          ) : (
            <SectionHeading heading="Account Sign In" subheading={loginDescription} />
          )}

          <div>
            <form className="rounded pt-4 pb-8" onSubmit={e => submitSignin(e)}>
              <input
                className="text-xs my-2 font-sf-regular rounded-md focus:appearance-none border border-gray-600 w-full py-4 px-8 text-gray-700 focus:outline-none"
                id="email"
                name="email"
                type="email"
                value={provider.email}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                placeholder="Email"
                required
              />

              <input
                className="text-xs my-2 font-sf-regular rounded-md focus:appearance-none border border-gray-600 w-full py-4 px-8 text-gray-700 mb-1 focus:outline-none"
                name="password"
                id="password"
                type="password"
                placeholder="Password"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />

              {loader && (
                <div className="flex justify-center items-center">
                  <Loader small />
                </div>
              )}

              <div className="flex items-center justify-between">
                <button
                  disabled={loader}
                  className="font-sf-regular w-full bg-primary-color hover:text-primary-color hover:bg-cus-blue text-white text-sm py-3 px-4 mt-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="submit">
                  Sign In
                </button>
              </div>

              <div className="flex justify-end">
                <a
                  href="/forgot_password"
                  className="font-sf-regular text-xs hover:underline text-t-gray">
                  Forgot Password?
                </a>
              </div>

              {!onSuccess && <OrLine />}

              <div className="flex items-center justify-between mt-2">
                {!onSuccess ? (
                  <div className="items-center flex flex-col">
                    <button
                      onClick={() => {
                        if (props.showSignup) props.showSignup(false);
                        else history.push('/signup');
                      }}
                      disabled={loader}
                      className="font-sf-regular w-full border border-gray-600 hover:text-white hover:bg-primary-color text-primary-color text-sm py-3 px-4 rounded-lg focus:outline-none focus:shadow-outline"
                      type="button">
                      Player Sign up
                    </button>

                    <PrivacyText className='text-center' />
                  </div>
                ) : (
                  <div
                    style={{ height: 1, backgroundColor: '#DBDBDB' }}
                    className="flex w-full my-3 self-center"
                  />
                )}
              </div>

              {onSuccess && (
                <div className="flex flex-col items-center">
                  <SectionHeading heading="New Account" subheading={newAccountSubHead} />
                  <button
                    onClick={() => {
                      props.showSignup(true);
                    }}
                    className="font-sf-regular w-full bg-primary-color hover:text-primary-color hover:bg-cus-blue text-white text-sm py-3 px-4 mt-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="button">
                    Sign Up
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(LoginForm);
