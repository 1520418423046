import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { ImageEdit, InputLabel } from '../../Shared';
import { profileIcon } from '../../../assets/img';
import 'react-datepicker/dist/react-datepicker.css';
import SelectDatepicker from 'react-select-datepicker';
import { customStyles, genderOptions, removeSeparator } from '../../../constants';

function AccountInfo({ provider, onChange, onImageChange, currentUser, validatePhone }) {
  return (
    <div>
      <div className="flex items-center justify-center my-2 flex-col self-center">
        <img
          className="rounded-full object-cover h-20 w-20 mb-4"
          src={
            provider.img
              ? provider.img
              : currentUser && currentUser.profile_image
              ? currentUser.profile_image
              : profileIcon
          }
        />

        <div className="flex bg-grey-lighter">
          <ImageEdit afterEditDone={(image, imageFile) => onImageChange(image, imageFile)} />
        </div>
      </div>

      <div className="p-5">
        <div className="container-wrap">
          <div className="container-half pr-0 md:pr-3">
            <InputLabel label="Player First Name" />
            <input
              className="text-input form-control"
              required
              placeholder="First name"
              value={
                provider.firstName !== null
                  ? provider.firstName
                  : currentUser && currentUser.first_name
              }
              onChange={e => onChange('firstName', e.target.value)}
            />
          </div>

          <div className="container-half">
            <InputLabel label="Player Last Name" />
            <input
              className="text-input form-control"
              placeholder="Last name"
              required
              value={
                provider.lastName !== null
                  ? provider.lastName
                  : currentUser && currentUser.last_name
              }
              onChange={e => onChange('lastName', e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col mt-3">
          <InputLabel label="Email" />
          <input
            className="text-input form-control"
            id="email"
            type="email"
            value={provider.email !== null ? provider.email : currentUser && currentUser.email}
            onChange={e => onChange('email', e.target.value)}
            required
          />
        </div>

        <div className="container-wrap items-end mt-3">
          <div className="container-half rounded pr-0 md:pr-4">
            <InputLabel label="Gender" />
            <Select
              name="gender"
              styles={customStyles}
              maxMenuHeight={180}
              className="primary-text-regular"
              isSearchable={false}
              components={removeSeparator}
              value={
                provider.gender
                  ? provider.gender
                  : currentUser && {
                      label: currentUser.gender,
                      value: currentUser.gender === 'male' ? 1 : 2,
                    }
              }
              placeholder="Select gender"
              onChange={selectedOption => onChange('gender', selectedOption)}
              options={genderOptions}
            />
          </div>

          <div className="container-half">
            <InputLabel label="Phone Number" />

            <div className="w-full">
              <InputMask
                name="contact"
                value={
                  provider.contact !== null
                    ? provider.contact
                    : currentUser && currentUser.contact_number
                }
                placeholder="(555) 555-5555"
                style={{ outline: 0, fontFamily: 'SF-light' }}
                className="text-input form-control"
                mask="+1 (999) 999-9999"
                maskChar=" "
                onChange={e => validatePhone(e.target.value)}
              />
            </div>
          </div>
        </div>

        <p className={`${provider.invalidPhone ? 'text-right' : 'hidden'}`}>
          <small className="primary-text-regular text-red-700">Invalid phone number</small>
        </p>

        <div className="flex flex-col w-full mt-3">
          <InputLabel label="Birthdate" />

          <div className="w-full">
            <SelectDatepicker
              className="text-sm"
              format="month/day/year"
              onDateChange={date => {
                onChange('dob', date);
              }}
              required
              value={
                provider.dob
                  ? new Date(moment(provider.dob).format('YYYY-MM-DD'))
                  : currentUser &&
                    currentUser.dob &&
                    new Date(moment(currentUser.dob).format('YYYY-MM-DD'))
              }
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date(moment().subtract(10, 'years').calendar())}
              showLabels={false}
            />
          </div>
        </div>

        <div className="flex flex-col mt-3 w-full">
          <InputLabel label="Password" />

          <div className="w-full mt-1">
            <div className="flex items-center">
              <button
                type={'button'}
                onClick={() => onChange('changePassModal', true)}
                className={'focus:outline-none mr-1 hover:text-blue-800'}>
                <span className="regular-black text-sm underline text-blue-500 normal-case">
                  Click here
                </span>
              </button>
              <span className="regular-black text-sm normal-case">to change the password</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInfo;
