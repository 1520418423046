import React from 'react';
import { InputLabel } from '../../Shared';
import { formattedPositions } from '../../../utils/utilities';

const DetailBlock = ({ value, title }) => (
  <div className="w-full">

    <div className="px-1">
      <small className={`text-sm font-sf-regular capitalize`} style={{opacity: .8}}> {title} </small>
    </div>

    <div className={`px-1 mb-2 flex items-center border rounded-md h-8`}>

      <div className="row flex w-full items-center px-1 ">
        <div className="flex-1">
            <small className={`text-sm primary-text-regular ${title === 'email' && 'lowercase'}`}>
              {value}
            </small>
        </div>
      </div>
    </div>
  </div>
);

const CustomerDetail = ({ editDetails, user }) => {
  const getRespectiveAttribute = item => {
    switch (item) {
      case 'firstName':
        return user.data.attributes.first_name;
      case 'lastName':
        return user.data.attributes.last_name;
      case 'address':
        return `${user.data.attributes.city}, ${user.data.attributes.state}`;
      case 'email':
        return user.data.attributes.email;
      case 'position':
        return formattedPositions(user.data.attributes.position);
      case 'highSchool':
        return user.data.attributes.high_school;
      case 'college':
        return user?.data?.attributes?.college?.data?.attributes?.nickname
      case 'club':
        return user.data.attributes.club;
      case 'class':
        return user.data.attributes.passing_year;
      case 'phoneNumber':
        return user.data.attributes.contact_number;
      default:
        return '';
    }
  };

  return (
    <div>
      <div className="flex justify-between w-full">
        <DetailBlock
          title={'player first Name'}
          value={getRespectiveAttribute('firstName')}
          editDetails={editDetails}
        />
        <div className='p-1'></div>
        <DetailBlock
          title={'player last Name'}
          value={getRespectiveAttribute('lastName')}
          editDetails={editDetails}
        />
      </div>

      {getRespectiveAttribute('college') && (
        <DetailBlock
          title={'college'}
          value={getRespectiveAttribute('college')}
          editDetails={editDetails}
        />
      )}

      <DetailBlock
        title={'high school'}
        value={getRespectiveAttribute('highSchool')}
        editDetails={editDetails}
      />

      <div className="flex justify-between w-full">
        <DetailBlock
          title={'club'}
          value={getRespectiveAttribute('club')}
          editDetails={editDetails}
        />
        <div className='p-1'></div>
        <DetailBlock
          title={'High School Grad Year'}
          value={getRespectiveAttribute('class')}
          editDetails={editDetails}
        />
      </div>

      <DetailBlock
        title={'position'}
        value={getRespectiveAttribute('position')}
        editDetails={editDetails}
      />

      <DetailBlock
        title={'Current Location'}
        value={getRespectiveAttribute('address')}
        editDetails={editDetails}
      />
      <div className="flex justify-between w-full">
        <DetailBlock
          title={'email'}
          value={getRespectiveAttribute('email')}
          editDetails={editDetails}
        />
        <div className='p-1'></div>
        <DetailBlock
          title={'Phone Number'}
          value={getRespectiveAttribute('phoneNumber')}
          editDetails={editDetails}
        />
      </div>

    </div>
  );
};

export default CustomerDetail;
