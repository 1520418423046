import { Modal } from "react-bootstrap";
import React, { useState } from 'react';
import appleStoreLogo from '../../assets/img/app_store.png'
import googlePlayLogo from '../../assets/img/google_play.png'
import { Link } from "react-router-dom";



function ContactTrainer(props) {
  const [showModal, setShowModal] = useState(false);

  const { onHide } = props;

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="primary-button w-full md:w-32 mr-0 md:mr-3">
        Contact Trainer
      </button>
      {
        showModal && <Modal
          show={true}
          onHide={() => setShowModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-full">
              <h2 className="text-xl text-center">Contact Trainer</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '60vh', overflow: 'auto' }} className="p-2 text-center">
            <h4 className="m-4 mt-2 text-base">Download our free app to communicate with your trainer </h4>
            <div className="flex w-full justify-evenly">
              <Link to={{pathname: 'https://apps.apple.com/us/app/trayvo/id1583924749'}}
                  rel="noopener noreferrer"
                  target="_blank">
                    <img className="object-cover w-40"
                      src={appleStoreLogo}
                      alt='' />
              </Link>
              {/*<Link to={{pathname: '#'}}*/}
              {/*    rel="noopener noreferrer"*/}
              {/*    target="_blank">*/}
              {/*      <img className="object-cover w-40"*/}
              {/*        src={googlePlayLogo}*/}
              {/*        alt='' />*/}
              {/*</Link>*/}
            </div>


          </Modal.Body>

        </Modal>
      }
    </>
  );
}

export default ContactTrainer;
