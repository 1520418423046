import React, { useEffect, useContext } from 'react';
import {sport, DefaultAvatar} from '../../../assets/img';
import ProgressiveImage from 'react-progressive-image';
import { StoreContext } from 'index';
import { TrayvoBadge } from '../../Shared';

const SmallText = ({ text, fontSize }) => (
  <span className={`primary-text-regular pr-1`} style={{ fontSize: fontSize || 12 }}>
    {text}
  </span>
);

export function GroupBanner({ group }) {
  const store = useContext(StoreContext);

  useEffect(() => {
    store.userStore.changeHeaderColor(group.color_code ? group.color_code : null);
    return () => {
      store.userStore.changeHeaderColor(null);
    };
  }, [group]);

  return (
    <div className="w-full">
      <div className="p-4 md:p-6 w-full flex items-center justify-between flex-wrap relative bg-white">
        <div className="absolute top-0 right-0">
          <TrayvoBadge color={group.color_code ? group.color_code : null} />
        </div>

        <div className="w-full">
          <div className="flex items-center justify-center">
            {group.logo_url &&
              <ProgressiveImage src={sport} placeholder={DefaultAvatar}>
                { src => (
                  <img
                    src={group.logo_url ? group.logo_url : DefaultAvatar}
                    className="rounded-full h-24 w-24 border-2 border-white object-cover"
                  />
                ) }
              </ProgressiveImage>
            }

            <div className="flex flex-col ml-4 mt-1">
              <SmallText text={group.name} fontSize={25} />

              <div className="flex items-center content-center pt-2">
                {group.facebook_url && (
                  <a
                    href={group.facebook_url}
                    className="fa fa-facebook border-none"
                    target="_blank"
                  />
                )}
                {group.twitter_url && (
                  <a href={group.twitter_url} className="fa fa-twitter border-none" target="_blank" />
                )}
                {group.instagram_url && (
                  <a
                    href={group.instagram_url}
                    className="fa fa-instagram border-none"
                    target="_blank"
                  />
                )}
                {group.website_url && (
                  <a href={group.website_url} className="fa fa-link border-none" target="_blank" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
