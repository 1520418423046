import React, { createRef, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { ModalBackground } from '.';
import {getCroppedImg, readMaxSizeImageFile} from '../../utils/imageCropUtils';

const ImageEdit = ({ afterEditDone }) => {
  const imageRef = createRef();
  const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [rotation, setRotation] = useState(0);
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const triggerImageSelect = () => imageRef.current.click();
  const onSelectImage = async e => {
    const { files } = e.target;

    if (files?.length > 0) {
      let imageDataUrl = await readMaxSizeImageFile(files[0]);
      setImage(imageDataUrl);
      setFileInputKey(Date.now());
    }
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const doneCropping = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedArea, rotation);
      const file = new File([croppedImage], `${Date.now()}-img`, { type: croppedImage.type });
      afterEditDone(URL.createObjectURL(croppedImage), file);
      setImage(null);
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedArea, rotation]);

  return (
    <React.Fragment>
      <div
        className={
          image
            ? `fixed inset-0 pin pb-4 inset-0 inset-x-0 inset-y-0 flex items-center justify-center z-10`
            : 'hidden'
        }>
        <ModalBackground />

        <div
          class="bg-white overflow-scroll max-h-screen shadow-xl transform transition-all w-full border-gray-700 border"
          role="dialog"
          aria-modal="true"
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            borderRadius: '10px',
          }}
          aria-labelledby="modal-headline">
          <div className="w-full relative" style={{ height: '80%' }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              cropShape="round"
              style={{
                containerStyle: {
                  width: '100%',
                  height: '100%',
                },
              }}
            />
          </div>

          <div className="relative flex justify-center items-center flex-col mt-2">
            <div className="md:w-2/4 w-3/4 my-2">
              <small className="font-sf-regular text-black"> Zoom: </small>
              <input
                className="slider"
                type="range"
                value={zoom}
                min={1}
                max={2}
                onChange={e => setZoom(e.target.value)}
                step={0.1}
              />
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                className="mx-1 text-primary-color rounded-md mt-2 w-24 border border-gray-500 py-1 text-xs"
                onClick={doneCropping}>
                Done
              </button>

              <button
                type="button"
                className="mx-1 text-primary-color rounded-md mt-2 w-24 border border-gray-500 py-1 text-xs"
                onClick={() => setImage(null)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <input
        type="file"
        accept="image/*"
        ref={imageRef}
        key={fileInputKey}
        hidden
        onChange={onSelectImage}
      />

      <button
        className="secondary-button bg-white cursor-pointer py-1"
        type="button"
        onClick={triggerImageSelect}>
        Upload a picture
      </button>
    </React.Fragment>
  );
};

export default ImageEdit;
