import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootStore from './mobXstore';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  // integrations: [new Integrations.BrowserTracing()],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay(),
    // new Integrations.BrowserTracing()
  ],
  // tracesSampleRate: 1.0,
});

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const rootStore = new RootStore();
  return (
    <StoreContext.Provider
      value={{
        rootStore: rootStore,
        userStore: rootStore.Store,
        atheleteStore: rootStore.AtheleteStore,
        paymentStore: rootStore.PaymentStore,
      }}>
      {children}
    </StoreContext.Provider>
  );
};

const render = AppRoutes =>
  ReactDOM.render(
    <React.StrictMode>
      <StoreProvider>
        <div>
          <ToastContainer position="top-center" autoClose={3000} />

          <AppRoutes />
        </div>
      </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

render(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
