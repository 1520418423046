import React, {useState} from 'react';
import SigninForm from './SignIn';
import { TRAINER_BASE_URL } from '../../../constant';
import { LogoRound } from '../../../assets/img';
import { default as Landing } from '../../../assets/img/landing/2.jpg';
import {useLocation} from "react-router-dom";

const buttonClasses = `
  text-lg uppercase rounded-md text-white hover:text-black font-sf-bold
  bg-primary-color border-2
  w-1/2 py-3 md:my-0
  focus:outline-none
`;

function SigninModal() {
  const search = useLocation().search;
  const revealed = new URLSearchParams(search).get("revealed");
  const [showSignIn, setShowSignIn] = useState(!!revealed);

  const revealSignIn = () => {
    setShowSignIn(true);
  }

  return (
    <div className="landing-background" style={{ backgroundImage: "url(" + Landing + ")" }}>

      <div className={`landing-transition backdrop min-h-screen ${showSignIn ? 'pt-0' : 'pt-10'}`} >
        {/*<img className="object-cover h-12 mx-4" src={LogoRound} />*/}

        <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto opacity-90">
          <div
            className="w-full md:w-1/3 overflow-hidden rounded-lg mx-4 md:mx-0"
            style={{minHeight: '480px'}}>

            <div className="w-full flex justify-content-center mb-10 ">
              <img src={LogoRound}/>
            </div>

            <div className=" flex w-full justify-between mb-5">
              <button className={`${buttonClasses}  ${showSignIn ? '' : 'border-primary-color'} mr-1`}
                onClick={ revealSignIn }>
                For PLAYERS
              </button>

              <button
                onClick={() => {
                  window.location.replace(TRAINER_BASE_URL + '?revealed=true');
                }}
                className={`${buttonClasses} border-primary-color`}>
                For TRAINERS
              </button>
            </div>

            <div className={`landing-transition collapsible  ${showSignIn ? 'expanded' : 'collapsed'}`}>
              <SigninForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SigninModal;
