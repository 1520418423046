import React, { Fragment } from 'react';
import { formatTime, formatDate, convertDate, getAthleteName } from '../../../utils/utilities';
import { faMapMarkerAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const getDuration = (fromTime, toTime) => {
  let startTime = moment(fromTime);
  let endTime = moment(toTime);
  return endTime.diff(startTime, 'minutes');
};

const TextWithIcon = ({ icon, location }) => (
  <div className={'flex items-center my-1'}>
    <FontAwesomeIcon icon={faMapMarkerAlt} size="xs" className="mr-2" color="#808387" />

    <span className="font-sf-regular text-xs" style={{ color: '#808387' }}>
      {location}
    </span>
  </div>
);

const NextAvailability = ({ athletes, showFullSchedule, bookButtonClick, upcomingTrainings }) => {
  return (
    <div className="flex flex-row overflow-x-auto w-full items-center pb-1">
      {athletes &&
        athletes
          .filter(({ attributes }) => attributes.next_availability && attributes.latest_calendar_entry)
          .slice(0, 4)
          .sort((a, b) => new Date(a?.attributes?.latest_calendar_entry?.booking_date) - new Date(b?.attributes?.latest_calendar_entry?.booking_date))
          .map(
            (
              {
                attributes: {
                  latest_calendar_entry,
                  next_availability,
                  profile_image,
                  provided_services,
                  first_name,
                  last_name,
                  city,
                  state,
                  passing_year,
                  slug,
                  sport_name,
                  sport,
                },
              },
              index
            ) => {
              return (
                <div
                  key={index}
                  onClick={() =>
                    bookButtonClick(slug, provided_services, next_availability.selected_trainings)
                  }
                  className="bg-white mr-2 rounded-lg border border-stone-800 flex flex-col items-center justify-between p-4 mt-3 mb-1 cursor-pointer hover:bg-gray-200"
                  style={{ minHeight: '12rem', minWidth: '12rem' }}>
                  <div className="flex flex-col items-center">
                    <small className="text-primary-color" style={{ fontSize: 20 }}>
                      {convertDate(formatDate(latest_calendar_entry.booking_date))}
                    </small>
                  </div>

                  <div className="flex flex-col items-center">
                    <small className="medium-black text-primary-color">{sport_name}</small>

                    <small className="primary-text-regular">
                      {formatTime(next_availability.from_time)} -
                      {formatTime(next_availability.to_time)}
                    </small>

                    <div className="flex items-center border border-gray-400 border-l-0 border-r-0 border-b-0 pt-2 mt-2">
                      <img
                        src={profile_image}
                        className="rounded-full h-4 w-4 object-cover shadow-md border border-gray-600"
                      />

                      <small className="font-sf-medium text-sm pl-2">
                        {getAthleteName({ first_name, last_name, passing_year })}
                      </small>
                    </div>

                    <TextWithIcon location={city + ', ' + state} />
                  </div>
                </div>
              );
            }
          )}

      <div
        onClick={() => showFullSchedule()}
        className="bg-white my-1 mx-2  rounded-lg border border-stone-800 flex flex-col items-center justify-center p-6 mt-3 cursor-pointer"
        style={{ minHeight: '12rem', minWidth: '12rem' }}>
        <div className="rounded-full h-10 w-10 shadow-md flex justify-center items-center hover:bg-gray-200 border border-gray-200">
          <FontAwesomeIcon icon={faArrowRight} size="lg" color="#808387" />
        </div>

        <small className="text-primary-color font-sf-medium text-sm pt-2">View full schedule</small>
      </div>
    </div>
  );
};

export default NextAvailability;
