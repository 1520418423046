import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { StoreContext } from 'index';
import Loader from 'components/Shared/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { formatDate } from '../../../utils/utilities';
import 'flatpickr/dist/themes/airbnb.css';
import SelectLocationModal from '../Profile/SelectLocationModal';
import { TrayvoBadge, Training } from '../../Shared';
import CalendarSelection from './CalendarSelection';
import TimeSlotSelection from './TimeSlotSelection';
import { extractSlots } from './slots.helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {TrainerBadge} from "../../Shared/TrainerBadge";
import {DISABLED_TRAININGS} from "../../../constants";

function BookService(props) {
  const history = useHistory();
  let location = useLocation();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [service, setService] = useState(null);
  const [showSelectModal, setSelectModal] = useState(null);
  const [selectedLocation, setLocation] = useState(null);
  const [selectedAddress, setAddress] = useState(null);

  let athlete = toJS(store.atheleteStore.athelete);
  let storeSelectedLocation = toJS(store.userStore.selectedLocation);
  let requestedBookings = toJS(store.atheleteStore.requestedBookings);
  let proccedDivRef = useRef(null);

  useEffect(() => {
    let {
      match: { params },
    } = props;
    store.userStore.hideHeader(true);
    store.atheleteStore
      .getAthelete(params.athleteSlug, `${params.athleteSlug}-${params.serviceSlug}`)
      .then(response => {
        if (response.attributes) {
          const trainings = response.attributes.provided_services.data;
          const constructedSlug = `${params.athleteSlug}-${params.serviceSlug}`;
          let serviceFiltered = trainings
            .filter(item => {
              return item.attributes.slug === constructedSlug;
            })
            .filter((training) => {
              console.log('Training', training);
              return DISABLED_TRAININGS.includes(training.attributes.id) === false
            });

          setService(serviceFiltered && serviceFiltered[0]);

        }
        setLoader(false);
      });
  }, []);

  const scrollToBottom = () => {
    proccedDivRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const bookAthelete = () => {
    if (service.attributes.training_type !== 'virtual' && !(+service.id === +storeSelectedLocation?.trainingId)) {
      const availableLocations = service.attributes.provided_service_locations.data;
      if (availableLocations.length === 1) {
        const location = availableLocations[0].attributes;
        setAddress(location.address);
        setLocation(location.location);

      } else {
        store.userStore.resetLocationAddress();
        setSelectModal(true);
      }

    } else {
      moveToCheckout();
    }
  };

  const moveToCheckout = () => {
    localStorage.setItem('serviceLocation', JSON.stringify(location));

    history.push({
      pathname: `/bookings/checkout`,
      state: {
        selectedSlot: selectedSlot,
        bookingDate: selectedSlot?.referenceDate,
        providedService: service,
      },
    });
  };

  useMemo(() => {
    if (service) {
      store.userStore.setLocationAddress(selectedLocation, selectedAddress, service.id);
      moveToCheckout();
    }
  }, [selectedLocation]);

  const slots = extractSlots(athlete, service);

  const onDaySelected = (date, trainingSpecific) => {
    if (date) {
      setSelectedDate(formatDate(date));
      if (trainingSpecific) {
        setSelectedTime(null);
        setSelectedEntry(trainingSpecific);
        scrollToBottom();
      }

    } else {
      setSelectedDate(null);
    }
  }

  const onBackClick = () => {
    if (selectedDate) {
      setSelectedDate(null);
    } else {
      history.push("/" + athlete.slug);
    }
  }

  const TrainerInfo = ({loader, athlete}) => {
    return (!loader && athlete) ?
      <div className="hidden md:block bg-white absolute p-3 pb-8 z-10 border"
        style={{
          left: 30,
          top: 0,
          borderBottomLeftRadius: 100,
          borderBottomRightRadius: 100,
        }}>
        <TrainerBadge athlete={athlete} />
      </div>
      : ''
  }

  const isPackage = service?.attributes?.package;
  const allSlotsBooked = service?.attributes?.all_slots_booked;

  return (
    <div className="bg-white min-h-screen w-full p-1 overflow-x-hidden relative flex items-start justify-center">

      <TrainerInfo loader={loader} athlete={athlete}/>

      {!loader && athlete ? (
        <div className="md:my-8 w-full md:w-9/12 xl:w-8/12 h-full">

          <div className='flex items-center justify-start w-full md:hidden mt-1'>
            <button onClick={() => onBackClick()} className="ml-2 mr-4 leading-none">
              <FontAwesomeIcon icon={faChevronLeft} size={'lg'} color="#565656" />
            </button>
            <TrainerBadge athlete={athlete} mobile />
            <div className="ml-auto">
              <TrayvoBadge />
            </div>

          </div>

          {service ? (
            <div className="flex flex-col bg-white md:flex-row md:border md:mt-6 w-100 rounded relative">

              <div className="hidden md:block md:absolute" style={{ top: '1px', right: '1px' }}> <TrayvoBadge /> </div>

              <div className='w-full absolute' style={{ top: 0, left: 0 }}>
                <button onClick={() => onBackClick()} className="hidden md:block m-4 leading-none focus:outline-none">
                  <FontAwesomeIcon icon={faChevronLeft} size={'lg'} color="#042E60" />
                </button>
              </div>

              <div className="flex justify-center w-full h-auto md:w-1/2 my-6 md:my-4 px-2 md:p-8">
                <Training
                  trainingAttributes={service.attributes}
                  athlete={athlete}
                  showTrainingType
                  hideDescription={false}
                  showFullAddress={true}>
                </Training>
              </div>

              <div className="flex flex-col p-2 md:p-8 md:my-4 justify-start items-center w-full md:w-1/2 md:h-auto border-t md:border-0 md:border-l">

                { /* PACKAGE - straight to checkout */
                  isPackage &&
                    <div className="flex items-center justify-center w-full h-full">
                      {!allSlotsBooked ?
                        <button
                          className="primary-button w-32 mx-2 h-auto"
                          style={{backgroundColor: '#042e60', color: 'white'}}
                          onClick={() => bookAthelete()}>
                          Book Now
                        </button>
                        :
                        <p>This training has no more spots available</p>
                      }
                    </div>
                }


                {  /* Date selection */
                  !isPackage && !selectedDate &&
                    (
                      slots?.length > 0
                        ? <CalendarSelection slots={slots}
                                           selectedDate={selectedDate}
                                           athelete={athlete}
                                           serviceId={service.id}
                                           onDaySelected={onDaySelected}
                          />
                        : <div className="mt-5 mr-5">
                            <small className="text-black font-sf-regular">
                              No slots available for the following athlete yet!
                            </small>
                          </div>
                    )
                  }
                { /* Slot selection */
                  !isPackage && selectedDate &&
                    (
                      <div className='flex flex-col justify-between md:h-full'>
                        <TimeSlotSelection selectedEntry={selectedEntry}
                          slots={slots}
                          selectedDate={selectedDate}
                          service={service}
                          selectedTime={selectedTime}
                          requestedBookings={requestedBookings}
                          bookAthelete={bookAthelete}
                          onDaySelected={onDaySelected}
                          setSelectedTime={setSelectedTime}
                          setSelectedSlot={setSelectedSlot}
                          scrollToBottom={scrollToBottom}
                        />
                        {selectedTime &&
                          <div
                            className="flex justify-center w-full">
                            <button
                              disabled={selectedTime === null}
                              className={`primary-button w-32 mx-2 ${!selectedTime && 'cursor-not-allowed'}`}
                              style={{ backgroundColor: '#042e60', color: 'white' }}
                              onClick={() => bookAthelete()}>
                              Next
                            </button>
                          </div>
                        }
                      </div>
                    )
                }
              </div>
            </div>
          ) : (
            <div className="h-64 flex bg-white flex-col text-center px-20 justify-center content-center items-center">
              <h2 className="font-bold">Looks like this trayvo link has stopped training</h2>
              <small className="mt-3 text-sm">
                Check the spelling or ask the trainer in case they updated the link or it's no longer in use
              </small>

            </div>
          )}

          <div ref={proccedDivRef} className="flex justify-center font-sf-regular w-full bottom-0 left-0" style={{ bottom: '18px' }}>
            <Link className="bg-white p-3 rounded-lg text-gray-700 text-sm"
              to={{ pathname: "mailto:train@trayvo.com" }}
              target='_blank'>
              Need Help? Contact us
            </Link>
          </div>

        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {service?.attributes?.provided_service_locations?.data?.length > 0 && (
        <SelectLocationModal
          visible={showSelectModal}
          locations={service.attributes.provided_service_locations.data}
          selectedLocation={selectedLocation}
          selectedAddress={selectedAddress}
          setSelectModal={value => {
            setSelectModal(value);
          }}
          setLocationChoice={(location, address) => {
            setAddress(address);
            setLocation(location);
          }}
        />
      )}
    </div>
  );
}
export default observer(BookService);
