import React, { Fragment } from 'react';
import { TrayvoBadge } from './index';
import { Placeholder, Tiktok } from '../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getSocialUrl,
  getCollegeName,
  getPassingYear,
  getHighSchoolName,
} from '../../utils/utilities';
import { Link } from 'react-router-dom';

// const SmallText = ({ text, black, semibold, bold, margin }) => (
//   <span
//     className={`text-sm capitalize ${black ? 'text-black' : 'text-t-gray'} ${
//       bold ? 'font-sf-bold' : semibold ? 'font-sf-semibold' : 'font-sf-regular'
//     } ${margin ? margin : ''}`}>

//     {text}
//   </span>
// );

const TextContainer = ({ text, small, big, black, semibold, bold, margin }) => {
  let className = 'capitalize';
  className = className + (
    small
      ? ' text-sm'
      : big
        ? ' text-xl'
        : ' text-base'
  );

  className = className + (
    black
      ? ' text-black'
      : ' text-t-gray'
  );

  className = className + (
    bold
      ? ' font-sf-bold'
      : semibold
        ? ' font-sf-semibold'
        : ' font-sf-regular'
  );

  className = className + (
    margin
      ? ' ' + margin
      : ''
  );

  return <span className={className}> {text}</span>
};

const Dot = () => (
  <span className={'capitalize px-1 text-t-gray font-sf-semibold'} style={{ fontSize: 11 }}>
    {'•'}
  </span>
);
const TextWithIcon = ({ icon, text }) => (
  <div className={'flex flex-wrap items-center mb-4 -ml-4 md:ml-0'}>
    <FontAwesomeIcon icon={icon} className="primary-color-icon mr-1 fa-w-18" />
    <p className="text-t-gray font-sf-regular break-all text-sm">
      {text}
    </p>
  </div>
);

const TextWithDescription = ({ description, text }) => (
  <p className="text-t-gray font-sf-regular text-sm overflow-x-hidden pb-1 capitalize">
    <span className='w-24 md:mr-2 inline-block font-light'>{description}</span>
    <span className='inline-block text-black'>{text}</span>
  </p>
);

function TrainerBanner({ athlete }) {
  let currentAddress;

  if (athlete.city && athlete.state) {
    currentAddress = [athlete.city, athlete.state].join(', ').trim();
  }

  return (
      <div className='p-4 md:p-6 rounded-lg border border-stone-800 w-full flex flex-col md:flex-row bg-white relative'>
        <div className="absolute top-0 right-0">
          <TrayvoBadge />
        </div>

        {/* { getAthleteImageSrc(athlete, 'trainerBanner') } */}

        <div className='w-auto flex justify-center items-center mt-6 md:mt-0'>
          <img
            src={athlete.profile_image || Placeholder}
            className="object-cover bg-white rounded-lg w-40 h-40"
            alt=''
          />
        </div>

        <div className="w-full md:w-7/12">
          <div className="flex flex-col md:flex-row items-center add-margin-on-small">
            <div className="flex flex-col trainer-banner-info md:px-3 md:pr-6">
              <p className='text-black uppercase md:mb-1 text-2xl'>
                <span className="font-sf-light mr-2">
                  {athlete.first_name}
                </span>

                <span className="font-sf-bold">
                  {athlete.last_name}
                </span>
              </p>

              <div className="block w-full text-center md:flex md:items-center">
                <TextContainer text={getCollegeName(athlete)} small black />
                <TextContainer text={getPassingYear(athlete)} small black margin="ml-1" />

                {athlete?.jersey_number?.toString() && (
                  <Fragment>
                    <Dot />
                    <TextContainer text={`#${athlete.jersey_number}`} small black />
                  </Fragment>
                )}

                {athlete?.position?.length && <Dot />}
                {athlete?.position?.map((position, index, positions) => (
                  position &&
                    <TextContainer
                      key={index}
                      text={`${position}${index !== positions.length - 1 ? ',' : ''}`}
                      margin={index !== positions.length - 1 ? 'mr-1' : ''}
                      small
                      black />
                ))}

              </div>

              {(athlete?.profile_description) &&
                <p className="text-left font-sf-regular text-sm mt-2 normal-case whitespace-pre-wrap"
                  style={{ color: 'rgb(104, 104, 104)' }}>
                  {athlete.profile_description.slice(0, 260)}
                </p>
              }
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-5/12 md:px-3 md:pl-6 overflow-hidden mt-3 justify-between h-auto md:border-l"
          style={{ borderLeftColor: 'rgb(206 206 206)', borderLeftStyle: 'dashed' }}>
          <div className="flex flex-col justify-center md:justify-start md:items-start">
            {athlete.sport?.name && (
              <TextWithDescription description={'Sport'} text={`${athlete?.sport?.name || ''}`} />
            )}

            {(athlete.high_school?.data) && (
              <TextWithDescription description={'High School'} text={`${getHighSchoolName(athlete)}`} />
            )}

            {currentAddress && (
              <TextWithDescription description={'Current'} text={currentAddress} />
            )}

            {athlete.hometown && (
              <TextWithDescription description={'Hometown'} text={athlete.hometown.replace(', USA', '')} />
            )}
          </div>

          <div className="flex items-center content-center justify-center md:justify-start mt-4">
            {athlete.facebook && (
              <Link
                to={{ pathname: `${getSocialUrl('facebook')}${athlete.facebook}` }}
                className="fa fa-facebook"
                target="_blank"
              />
            )}
            {athlete.twitter && (
              <Link
                to={{ pathname: `${getSocialUrl('twitter')}${athlete.twitter}` }}
                className="fa fa-twitter"
                target="_blank"
              />
            )}
            {athlete.instagram && (
              <Link
                to={{ pathname: `${getSocialUrl('instagram')}${athlete.instagram}` }}
                className="fa fa-instagram"
                target="_blank"
              />
            )}
            {athlete.tiktok && (
              <Link to={{ pathname: `${getSocialUrl('tiktok')}${athlete.tiktok}` }}
                className="fa fa-tiktok"
                target="_blank">
                <img src={Tiktok} alt='' className="w-4 h-4" />
              </Link>
            )}
            {athlete.website && (
              <Link to={{ pathname: athlete.website }} className="fa fa-link" target="_blank" />
            )}
        </div>
      </div>
    </div>
  );
}

export default TrainerBanner;
