import {getAthleteImage} from "../../utils/utilities";
import React from "react";

export const TrainerBadge = ({ athlete, mobile }) => {
  return (
    <div className={`flex overflow-hidden ${mobile ? 'flex-row justify-center items-center ' : 'w-full flex-col text-center items-center'}`}>
      <a href={ "/" + athlete.slug}>
        {getAthleteImage(athlete, mobile ? 'small' : 'big')}
        <div className='uppercase text-xs md:text-sm text-black block md:flex md:flex-col md:mt-1'>
          <span className='text-light font-sf-light mx-1' style={{ marginBottom: -4 }}>{athlete.first_name || ''}</span>
          <span className='font-sf-bold'>{athlete.last_name || ''}</span>
        </div>
      </a>
    </div>
  )
};
