import React from 'react';
import { observer } from 'mobx-react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { formatDate } from '../../../utils/utilities';
import { PACKAGE_TRAININGS } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { findWindows } from 'windows-iana';

import momentTimezone from 'moment-timezone';


function CalendarSelection(props) {
  const { athelete, slots, serviceId, selectedDate } = props;
  const { onDaySelected } = props;

  console.log(serviceId);

  const options = {
    inline: true,
    defaultDate: slots.length > 0 && slots[0][0],
    // minDate: new Date(),
    enable: slots.map(slot => slot[0]),
    monthSelectorType: 'static',
    yearSelectorType: 'static',
  }

  const dayCreated = (dObj, dStr, fp, dayElem) => {
    if (
      moment().format('YYYY-MM-DD') ===
      moment(dayElem.dateObj).format('YYYY-MM-DD')
    ) {
      dayElem.className += ' current-date';

    } else if (
      Object.keys(athelete.generated_slots)
        .filter(function (key, index) {
          return athelete.generated_slots[key].length === 0;
        })
        .includes(formatDate(dayElem.dateObj)) ||
      moment(dayElem.dateObj).format() <
      moment().subtract(1, 'days').format()
    ) {
      dayElem.className += ' booked-date';

    } else {
      dayElem.className += ' bold-date';
    }
  }

  const selectionChanged = (date, dateStr) => {
    let trainingSpecific
    if (
      moment(date[0]).format() >= moment().subtract(1, 'days').format()
    ) {
      let filteredSlots = slots.filter(item => {
        return formatDate(item[0]) === formatDate(date[0]);
      })[0][1];

      trainingSpecific = filteredSlots.filter(item => {
        return item.selected_trainings.includes(parseInt(serviceId));
      });
    }

    onDaySelected(date[0], trainingSpecific);
  }

  return (

    <div className="text-center"
    // style={{transform: 'scale(1.15)'}}
    >
      <h3 className="text-black text-xl">
        Select Date
      </h3>

      <div className="mb-3">
        <p className="text-xs text-t-gray text-center">
          <FontAwesomeIcon
            icon={faMapPin}
            size="1x"
            className="primary-color-icon mr-1 fa-w-18"
          />
          {findWindows(momentTimezone.tz.guess())}
        </p>
      </div>

      { PACKAGE_TRAININGS.includes(+serviceId) &&
          <p style={{"color": "red"}}>To book all sessions, just choose one date</p>
      }


      <div className="flex justify-center">
        <Flatpickr
          hidden={true}
          onDayCreate={dayCreated}
          options={options}
          value={selectedDate ? selectedDate : moment().format('YYYY-MM-DD')}
          onChange={selectionChanged}
        />
      </div>
    </div>

  );
}

export default observer(CalendarSelection);

