import React, { useState, useContext, useEffect, useRef } from 'react';
import Loader from '../../Shared/Loader';
import { observer } from 'mobx-react';
import {DefaultAvatar} from '../../../assets/img';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { useHistory, Link, useParams } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import Select from 'react-select';
import { customStyles, removeSeparator } from '../../../constants';
import { TrainingAndLocationSelect, } from '../../Shared';
import {
  formatTime,
  formatDate,
  convertDate,
  getAthleteName,
  removeUnderScoreFromString,
} from '../../../utils/utilities';
import InfiniteScroll from 'react-infinite-scroller';
import { GroupBanner } from './GroupBanner';

const trainerOtherInfo = trainerInfo =>
  trainerInfo
    .filter(info => info)
    .map((info, index) => (info && index === 0 ? info : ` • ${info}`));

const ActionButton = ({ bookButtonClick }) => (
  <button
    onClick={e => {
      e.stopPropagation();
      return bookButtonClick();
    }}
    style={{
      transition: 'opacity 300ms',
      backgroundColor: 'rgb(5,46,96)',
      color: '#fff'
    }}
    className={'primary-button w-full md:w-32 mt-3 md:mt-0 opacity-87 hover:opacity-100'}>
    {'Book Now'}
  </button>
);

const SectionHeading = ({ title, primary, size }) => {
  return (
    <small className={`medium-black ${size || 'text-lg'}  ${primary && 'text-primary-color'}`}>{title}</small>
  );
};

const AthleteInfo = ({group, athletes, history, bookButtonClick, notifyMe}) => {
  return athletes && athletes.length > 0 ? (
    athletes.map(
      (
        {
          id,
          profile_image,
          first_name,
          last_name,
          sport_name,
          position,
          passing_year,
          city,
          state,
          next_availability,
          provided_services,
          sport,
          slug,
          jersey_number,
          athlete_type,
        },
        index
      ) => (
        <div key={index}>
          <div className="p-5 pl-5 pr-5 border-b border-stone-800 px-2 mr-1 flex flex-col items-center md:flex-row justify-between w-full">
            <div className="flex md:flex-row flex-col items-center w-full md:w-3/4">
              <ProgressiveImage src={profile_image} placeholder={DefaultAvatar}>
                {src => (
                  <img
                    src={src ? src : DefaultAvatar}
                    className="h-24 w-24 rounded-full mb-2 border object-cover"
                  />
                )}
              </ProgressiveImage>

              <div className="flex flex-col md:flex-row justify-between flex-wrap ml-3 w-full md:text-left text-center">
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="md:text-left text-center capitalize">
                    <Link to={`/${slug}`}>{getAthleteName({ first_name, last_name, passing_year })}</Link>
                  </div>

                  <small className="primary-text-regular">{sport_name}</small>

                  <small className="primary-text-regular">{group.name}</small>

                  <small className="primary-text-regular">
                    {athlete_type && removeUnderScoreFromString(athlete_type)}
                  </small>

                  <div className="flex md:justify-start justify-center">
                    <small className="primary-text-regular text-t-gray">
                      {trainerOtherInfo([
                        ...position,
                        passing_year,
                        jersey_number && `#${jersey_number}`,
                      ])}
                    </small>
                  </div>
                </div>

                <div className="flex flex-col md:w-1/2 justify-center md:border-dashed border-l border-stone-800 pl-0 md:pl-4">
                  <small className="primary-text-regular text-t-gray">
                    Current Location: {city + ', ' + state}
                  </small>

                  {false && next_availability && (
                    <small className="primary-text-regular text-t-gray">
                      {'Next Available: '}
                      {`${convertDate(
                        formatDate(next_availability.repetition_from)
                      )} from ${formatTime(next_availability.from_time)} to ${formatTime(
                        next_availability.to_time
                      )}`}
                    </small>
                  )}
                </div>
              </div>
            </div>

            <ActionButton
              id={id}
              history={history}
              notifyMe={() => notifyMe(id, getAthleteName({ first_name, last_name, passing_year }))}
              bookButtonClick={() =>
                bookButtonClick(slug)
              }
              nextAvailability={next_availability}
            />
          </div>
        </div>
      )
    )
  ) : (
    <div className="loader-container">
      <small className="primary-text-regular text-lg">No athletes available</small>
    </div>
  );
};

const Filter = ({ options, onChange, placeholder, value, props }) => (
  <div className="w-full md:flex-1 mr-0 mt-3 md:mr-3 md:mt-0 bg-white rounded-md">
    <Select
      {...props}
      value={value}
      styles={customStyles}
      components={removeSeparator}
      isSearchable={false}
      placeholder={placeholder}
      className="primary-text-regular"
      options={options}
      onChange={option => onChange(option)}
    />
  </div>
);

function Group(props) {
  const history = useHistory();
  const params = useParams();
  const groupSlug = params.group_slug;

  const [group, setGroup] = useState(false);
  const [selectionModal, setSelectionModal] = useState(null);
  const [selectedAthleteTrainings, setSelectedAthleteTrainings] = useState(null);

  const store = useContext(StoreContext);
  const sports = toJS(store.userStore.sports);
  let athletePagy = toJS(store.atheleteStore.athletePagy);

  useEffect(() => {
    loadRequired();
  }, []);


  const loadRequired = () => {
    return Promise.all([
      loadGroup(),
      loadSports()
    ]).then(([group, sports]) => {
      if (group) {
        group.athletes.sort((a, b) => {
          if(a.first_name < b.first_name) {
            return -1;
          } else if(a.first_name > b.first_name) {
            return 1;
          }

          return 0;
        })
        setGroup(group);
      }
    });
  }

  const loadGroup = () => {
      return store.atheleteStore.getGroup(groupSlug);
  }

  const loadSports = () => {
    let sportsPromise;
    if (sports && sports.length) {
      sportsPromise = Promise.resolve(sports);

    } else {
      sportsPromise = store.userStore.getSports();
    }

    return sportsPromise;
  }



  const bookButtonClick = async (slug, providedServices, selectedTrainings) => {
    const pathname = `/${slug}/`;
    return history.push({ pathname });


    if (
      providedServices.data &&
      providedServices.data.length === 1 &&
      providedServices.data[0].attributes.provided_service_locations.data.length === 1
    ) {
      if (providedServices.data[0].attributes.training_type !== 'virtual')
        store.userStore.setLocationAddress(
          providedServices.data[0].attributes.provided_service_locations.data[0].attributes.location.trim(),
          providedServices.data[0].attributes.provided_service_locations.data[0].attributes.address.trim(),
          providedServices.data[0].id
        );

      const pathname = `/${slug}/${providedServices.data[0].attributes.slug.split('-')[1]}`;
      history.push({ pathname });
    }

    let trainings =
      providedServices &&
      providedServices.data.filter(({ attributes: { id } }) => selectedTrainings.includes(id));

    setSelectedAthleteTrainings({ data: trainings });
    setSelectionModal(slug);
  };

  if (sports && sports[0] && sports[0].label !== 'All') {
    sports.unshift({ label: 'All', value: 'All', id: 0 });
  }

  return (
    <div className="flex flex-col content-center items-center p-4 pt-24 md:pt-16 min-h-screen">
      <div className="mt-3 overflow-hidden w-full md:w-3/4 rounded-lg border border-stone-800">
        {group && <GroupBanner group={group} />}
      </div>

      {group ? (
        <>
          <div className="mt-10 flex w-full  justify-center">
            <div className=" md:w-3/4 px-1 flex flex-col direction-change items-start justify-between">
              <SectionHeading title="Trainers" size="text-2xl" />
            </div>
          </div>

          <div className="w-full flex flex-col items-center content-center justify-center my-3">
            <div className="flex flex-col w-full md:w-3/4 items-center pb-2 justify-between">
              <div
                className="rounded-lg border border-stone-800  flex w-full bg-white flex-wrap justify-between">
                <InfiniteScroll
                  pageStart={1}
                  loadMore={() => {}}
                  className="w-full"
                  loader={
                    <div className="loader" key={0}>
                      Loading ...
                    </div>
                  }
                  hasMore={false}>
                  <AthleteInfo
                    athletes={group && group.athletes}
                    history={history}
                    bookButtonClick={(slug) =>
                      bookButtonClick(slug)
                    }
                    group={group}
                  />
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      {selectionModal && (
        <TrainingAndLocationSelect
          visible={selectionModal}
          slug={selectionModal}
          trainings={selectedAthleteTrainings}
          closeModal={() => {
            setSelectionModal(null);
            setSelectionModal(null);
            setSelectedAthleteTrainings(null);
          }}
        />
      )}
    </div>
  );
}

export default observer(Group);
