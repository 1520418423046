import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyText({ className }) {
  return (
    <div className={`primary-text-regular text-sm md:text-xs text-300 normal-case text-t-gray my-3 w-full ${className}`}>
      By signing up, you agree to our
      <Link
        to="/terms-of-services"
        className="medium-black focus:outline-none hover:text-gray-500 normal-case px-1 text-sm md:text-xs">
        Terms of Service
      </Link>
      and acknowledge that you have read our
      <Link
        to="/privacy-policy"
        className="medium-black focus:outline-none hover:text-gray-500 normal-case px-1 text-sm md:text-xs">
        Privacy Policy
      </Link>
      to learn how we collect, use, and share your data.
    </div>
  );
}

export default PrivacyText;
