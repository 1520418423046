import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { InputLabel, GooglePlacesInput, ImageEdit } from '../../Shared';
import Async from 'react-select/async';
import { Placeholder } from '../../../assets/img';
import { ifCurrentPlayer, ifPassedIn } from '../../../utils/utilities';
import { removeArrowInSelect, removeSeparator } from '../../../constants';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

const SectionHeading = ({ title }) => {
  return (
    <div className="text-left pt-4 mb-3">
      <small className="text-black font-sf-regular" style={{ fontSize: 16 }}>
        {title}
      </small>
    </div>
  );
};

function PlayerInfo({
  provider,
  onChange,
  dispatch,
  sports,
  customStyles,
  getSchoolsBySearch,
  getCollegesBySearch,
  getSportPositions,
  resetPositions,
  sportPositions,
}) {
  return (
    <div>
      <div className="flex items-center justify-center my-2 flex-col self-center">
        <img
          className="rounded-full object-cover h-20 w-20 mb-4"
          src={provider.img ? provider.img : Placeholder}
        />

        <div className="flex bg-grey-lighter">
          <ImageEdit
            afterEditDone={(image, imageFile) => {
              dispatch({ field: 'img', value: image });
              dispatch({ field: 'imgFile', value: imageFile });
            }}
          />
        </div>
      </div>

      <SectionHeading title="Teams" />

      <div className="container-wrap">
        <div
          className={
            ifPassedIn(provider.passing_year && provider.passing_year.value)
              ? 'container-half pr-0 md:pr-3'
              : 'mt-3 w-full'
          }>
          <InputLabel
            label={
              ifCurrentPlayer(provider.passing_year && provider.passing_year.value)
                ? 'Middle School'
                : 'School'
            }
          />

          {ifCurrentPlayer(provider.passing_year && provider.passing_year.value) ? (
            <div>
              <input
                className="text-input form-control"
                name="middleSchool"
                type="text"
                value={provider.middleSchool}
                placeholder="Middle School"
                onChange={e => onChange(e)}
                required
              />
            </div>
          ) : (
            <Async
              components={removeSeparator}
              className="primary-text-regular form-control w-full"
              placeholder=""
              value={provider.school}
              loadOptions={getSchoolsBySearch}
              styles={Object.assign(customStyles, removeArrowInSelect)}
              isSearchable
              onChange={selectedOption => dispatch({ field: 'school', value: selectedOption })}
            />
          )}
        </div>

        {ifPassedIn(provider?.passing_year?.value) && (
          <div className="container-half">
            <InputLabel label="College Commitment" />

            <div>
              <Async
                components={removeSeparator}
                className="primary-text-regular form-control"
                placeholder=""
                value={provider.college}
                loadOptions={getCollegesBySearch}
                styles={Object.assign(customStyles, removeArrowInSelect)}
                isSearchable
                onChange={selectedOption => dispatch({ field: 'college', value: selectedOption })}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-3">
        <InputLabel label="Club" />
        <input
          className="text-input form-control"
          name="club"
          value={provider.club}
          placeholder="Club"
          onChange={e => onChange(e)}
          style={{ height: '38px' }}
        />
      </div>

      <SectionHeading title="Details" />

      <div className="container-wrap">
        <div className="container-half pr-0 md:pr-3">
          <InputLabel label="Sport" />
          <Select
            name="sport"
            styles={customStyles}
            maxMenuHeight={180}
            className="primary-text-regular bg-main-background rounded-md"
            isSearchable={false}
            components={removeSeparator}
            value={provider.sport}
            placeholder="Select sport"
            onChange={selectedOption => {
              getSportPositions(selectedOption.id);
              resetPositions();
              dispatch({ field: 'sport', value: selectedOption });
            }}
            options={sports}
            required
          />
        </div>

        <div className="container-half">
          <InputLabel label="Jersey #" />
          <div>
            <input
              className="text-input form-control"
              type="number"
              name="jerseyNumber"
              value={provider.jerseyNumber}
              placeholder="Jersey #"
              onChange={e => onChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <InputLabel label="Position" />
        <div className="flex w-full">
          <div className="w-full md:w-1/3">
            <Select
              name="position"
              styles={customStyles}
              maxMenuHeight={180}
              isDisabled={!provider.sport}
              className="primary-text-regular bg-main-background rounded-md"
              isSearchable={false}
              value={provider.positionFirst}
              placeholder="Position"
              components={removeSeparator}
              onChange={selectedOption =>
                dispatch({ field: 'positionFirst', value: selectedOption })
              }
              options={sportPositions}
              required
            />
          </div>

          <div className="w-full md:w-1/3 px-1">
            <Select
              name="position"
              styles={customStyles}
              maxMenuHeight={180}
              className="primary-text-regular bg-main-background rounded-md"
              isSearchable={false}
              value={provider.positionSecond}
              isDisabled={!provider.sport}
              placeholder="Position 2"
              components={removeSeparator}
              onChange={selectedOption =>
                dispatch({ field: 'positionSecond', value: selectedOption })
              }
              options={sportPositions}
              required
            />
          </div>

          <div className="w-full md:w-1/3">
            <Select
              name="position"
              styles={customStyles}
              maxMenuHeight={180}
              className="primary-text-regular bg-main-background rounded-md"
              isSearchable={false}
              value={provider.positionThird}
              isDisabled={!provider.sport}
              placeholder="Position 3"
              components={removeSeparator}
              onChange={selectedOption =>
                dispatch({ field: 'positionThird', value: selectedOption })
              }
              options={sportPositions}
              required
            />
          </div>
        </div>
      </div>

      <div className="w-full mt-3">
        <InputLabel label="Time Zone" color="black" />

        <TimezonePicker
          className="w-full"
          absolute={false}
          value={provider.timeZone}
          placeholder="Select timezone..."
          onChange={timeZone => dispatch({ field: 'timeZone', value: timeZone })}
        />
      </div>

      <SectionHeading title="Current City" />

      <div className="flex flex-col">
        <div className="w-full">
          <GooglePlacesInput
            value={provider.currentLocation}
            setValue={val => dispatch({ field: 'currentLocation', value: val })}
            setData={val => dispatch({ field: 'currentLocationData', value: val })}
          />
        </div>
      </div>

      <SectionHeading title="About" />

      <Form.Group controlId="about" className="justify-center my-1">
        <div className="text-left w-full">
          <Form.Control
            as="textarea"
            style={{ minHeight: '100px' }}
            className="rounded-md w-full form-control p-2 font-sf-regular text-xs border border-light-gray"
            onChange={e => dispatch({ field: 'about', value: e.target.value })}
            value={provider.about}
            placeholder="About"
          />
        </div>
      </Form.Group>
    </div>
  );
}

export default PlayerInfo;
