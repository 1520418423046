import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import moment from 'moment';
import SelectDatepicker from 'react-select-datepicker';
import { InputLabel } from '../../Shared';
import { passingYear, removeSeparator } from '../../../constants';
import PrivacyText from 'components/Shared/PrivacyText';
import { isValidContactNumber } from 'utils/utilities';

function AccountPlayerInfo({
  provider,
  onChange,
  invalidPhone,
  guest,
  dispatch,
  validatePhone,
  customStyles,
  privacyChecked,
  setEmailConfirmed,
  changePrivacyChecked,
}) {
  const [emailRepeatError, setEmailRepeatError] = useState(false);
  const [email, setEmail] = useState(provider.email);
  const [repeat, setRepeat] = useState('');

  const [phone, setPhone] = useState({
    invalid: false,
    repeatInvalid: false,
    value: provider.phone,
    repeat: ''
  })

  let selectedYear = passingYear[13];
  if (provider.passing_year) {
    selectedYear = passingYear.find((y) => +y.value === +provider.passing_year.value);
  }

  const onEmailChanged = (value) => {
    setEmail(value);
    checkEmails(value, repeat);
  }

  const onRepeatEmailChanged = (value) => {
    setRepeat(value);
    checkEmails(email, value);
  }

  const checkEmails = (email, repeat) => {
    if (email === repeat) {
      setEmailRepeatError(false);
      setEmailConfirmed(true);
      onChange({
        target: { name: 'player_email', value: email }
      })

    } else {
      setEmailRepeatError(true);
      setEmailConfirmed(false)
      onChange({
        target: { name: 'player_email', value: false }
      })

    }
  }

  const onPhoneChanged = (value) => {
    if (!isValidContactNumber(value)) {
      setPhone({ ...phone, value, invalid: true });

    } else {
      checkPhones(value, phone.repeat);
    }
  }

  const onRepeatPhoneChanged = (value) => {
    checkPhones(phone.value, value);
  }

  const checkPhones = (value, repeat) => {
    const isValid = value === repeat;

    setPhone({
      ...phone,
      invalid: false,
      repeatInvalid: !isValid,
      value,
      repeat
    });

    onChange({
      target: { name: 'contact', value: isValid ? value : ''}
    });
  }

  return (
    <div>
      <div className="container-wrap">
        <div className="container-half pr-0 md:pr-4">
          <InputLabel label="Player First Name" small/>
          <div>
            <input
              className="text-input form-control text-sm font-sf-light"
              name="firstName"
              value={provider.firstName}
              placeholder="First Name"
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>

        <div className="container-half mt-3 md:mt-0">
          <InputLabel label="Player Last Name" small/>
          <div>
            <input
              className="text-input form-control text-sm font-sf-light"
              name="lastName"
              value={provider.lastName}
              placeholder="Last Name"
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <InputLabel label="Email" small/>
        <div>
          <input
            className="text-input form-control text-sm font-sf-light"
            name="email"
            type="email"
            onChange={e => onEmailChanged(e.target.value)}
            placeholder="Email"
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <InputLabel label="Confirm email" small/>
        <div>
          <input style={{ display: 'none' }} type="email" name="repeatEmail" />
          <input
            className="text-input form-control text-sm font-sf-light"
            name="repeatEmail"
            type="email"
            onChange={e => onRepeatEmailChanged(e.target.value)}
            placeholder="Confirm email"
            required
          />
        </div>

        {emailRepeatError ?
          <small style={{ color: 'red' }}>Emails do not match</small> : ''
        }
      </div>

      <div className="container-wrap mt-3">
        <div className="container-half pr-0 md:pr-4 ">
          <InputLabel label="Gender" small/>
          <Select
            name="gender"
            styles={customStyles}
            maxMenuHeight={180}
            className="bg-white text-sm font-sf-light mt-1 rounded-md focus:outline-none"
            isSearchable={false}
            components={removeSeparator}
            value={provider.gender}
            placeholder="Select gender"
            onChange={selectedOption => dispatch({ field: 'gender', value: selectedOption })}
            options={[
              { label: 'Female', value: 2 },
              { label: 'Male', value: 1 },
            ]}
            required
          />
        </div>

        <div className="container-half">
          <InputLabel label="HS Grad Year" small/>
          <Select
            name="class"
            styles={customStyles}
            maxMenuHeight={180}
            className="text-sm font-sf-light mt-1 bg-main-background rounded-md"
            isSearchable={false}
            components={removeSeparator}
            value={selectedYear}
            placeholder="Select HS Grad Year"
            onChange={selectedOption => dispatch({ field: 'passing_year', value: selectedOption })}
            options={passingYear}
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="container-wrap">
          <div className="container-half md:pr-4">
            <div className="text-left w-full">
              <InputLabel label="Phone Number" color="black" small />
              <InputMask
                  placeholder="(555) 555-5555"
                  style={{ outline: 0 }}
                  className="font-sf-light text-sm rounded input-mask form-control border block w-full px-2"
                  mask="+1 (999) 999-9999"
                  maskChar=" "
                  value={phone.value}
                  onChange={e => onPhoneChanged(e.target.value)}
              />
              {
                phone.invalid ?
                  <p className={'text-left'}>
                    <small className="text-sm font-sf-light text-red-800">Invalid phone number</small>
                  </p> : ''
              }
            </div>
          </div>

          <div className="container-half">
            <div className="w-full text-left">
              <InputLabel label="Confirm Phone Number" color="black" small/>
              <InputMask
                  autofocus=""
                  placeholder="(555) 555-5555"
                  style={{ outline: 0, fontFamily: 'SF-light' }}
                  className="text-sm font-sf-light rounded input-mask form-control border block w-full px-2"
                  mask="+1 (999) 999-9999"
                  maskChar=" "
                  value={phone.repeat}
                  onChange={e => onRepeatPhoneChanged(e.target.value)}
              />
              { !phone.invalid && phone.value && phone.repeatInvalid ?
                  <p className={'text-left'}>
                    <small className="text-sm font-sf-light text-red-800">Numbers don't match</small>
                  </p> : ''
              }
            </div>
          </div>
        </div>
      </div>


      <div className="flex mt-3 items-start w-full justify-start flex-col pt-2">
        <InputLabel label="Birthdate" small/>
        <div className="w-full">
          <SelectDatepicker
            className="pt-1 text-sm font-sf-light"
            format="month/day/year"
            onDateChange={date => {
              dispatch({ field: 'dob', value: date });
            }}
            required
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(moment().subtract(6, 'years').calendar())}
            showLabels={false}
            value={provider.dob}
          />
        </div>
      </div>
    </div>
  );
}

export default AccountPlayerInfo;
