import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import {GooglePlacesInput, InputLabel} from '../../Shared';
import {removeSeparator} from '../../../constants';
import PrivacyText from 'components/Shared/PrivacyText';
import {isValidContactNumber} from 'utils/utilities';

function AccountParentInfo({
                             provider,
                             onChange,
                             guest,
                             dispatch,
                             customStyles,
                             privacyChecked,
                             setEmailConfirmed,
                             changePrivacyChecked,
                           }) {
  const [emailRepeatError, setEmailRepeatError] = useState(false);
  const [email, setEmail] = useState(provider.email);
  const [repeat, setRepeat] = useState('');

  const [phone, setPhone] = useState({
    invalid: false,
    repeatInvalid: false,
    value: provider.phone,
    repeat: ''
  })

  const onEmailChanged = (value) => {
    setEmail(value);
    checkEmails(value, repeat);
  }

  const onRepeatEmailChanged = (value) => {
    setRepeat(value);
    checkEmails(email, value);
  }

  const checkEmails = (email, repeat) => {
    if (email === repeat) {
      setEmailRepeatError(false);
      setEmailConfirmed(true);
      onChange({
        target: {name: 'email', value: email}
      })

    } else {
      setEmailRepeatError(true);
      setEmailConfirmed(false)
      onChange({
        target: {name: 'email', value: false}
      })

    }
  }

  const onPhoneChanged = (value) => {
    if (!isValidContactNumber(value)) {
      setPhone({...phone, value, invalid: true});

    } else {
      checkPhones(value, phone.repeat);
    }
  }

  const onRepeatPhoneChanged = (value) => {
    checkPhones(phone.value, value);
  }

  const checkPhones = (value, repeat) => {
    const isValid = value === repeat;

    setPhone({
      ...phone,
      invalid: false,
      repeatInvalid: !isValid,
      value,
      repeat
    });

    onChange({
      target: {name: 'parent_contact_number', value: isValid ? value : ''}
    });
  }

  const setParentAddress = (addr) => {
    dispatch({field: 'parentAddress', value: addr})
  }

  return (
    <div>
      <div className="container-wrap">
        <div className="container-half pr-0 md:pr-4">
          <InputLabel label="Parent/Guardian First Name" small/>
          <div>
            <input
              className="text-input form-control text-sm font-sf-light"
              name="parent_first_name"
              value={provider.parent_first_name}
              placeholder="First Name"
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>

        <div className="container-half mt-3 md:mt-0">
          <InputLabel label="Parent/Guardian Last Name" small/>
          <div>
            <input
              className="text-input form-control text-sm font-sf-light"
              name="parent_last_name"
              value={provider.parent_last_name}
              placeholder="Last Name"
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <InputLabel label="Email" small/>
        <div>
          <input
            className="text-input form-control text-sm font-sf-light"
            name="email"
            type="email"
            onChange={e => onEmailChanged(e.target.value)}
            placeholder="Email"
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <InputLabel label="Confirm email" small/>
        <div>
          <input style={{display: 'none'}} type="email" name="repeatEmail"/>
          <input
            className="text-input form-control text-sm font-sf-light"
            name="repeatEmail"
            type="email"
            onChange={e => onRepeatEmailChanged(e.target.value)}
            placeholder="Confirm email"
            required
          />
        </div>

        {emailRepeatError ?
          <small style={{color: 'red'}}>Emails do not match</small> : ''
        }
      </div>

      <div className="mt-3">
        <InputLabel label="Gender" small/>
        <Select
          name="parent_gender"
          styles={customStyles}
          maxMenuHeight={180}
          className="bg-white text-sm font-sf-light mt-1 rounded-md focus:outline-none"
          isSearchable={false}
          components={removeSeparator}
          value={provider.parent_gender}
          placeholder="Select gender"
          onChange={selectedOption => dispatch({field: 'parent_gender', value: selectedOption})}
          options={[
            {label: 'Female', value: 2},
            {label: 'Male', value: 1},
          ]}
          required
        />
      </div>

      <div className="mt-3">
        <div className="container-wrap">
          <div className="container-half md:pr-4">
            <div className="text-left w-full">
              <InputLabel label="Phone Number" color="black" small/>
              <InputMask
                placeholder="(555) 555-5555"
                style={{outline: 0}}
                className="font-sf-light text-sm rounded input-mask form-control border block w-full px-2"
                mask="+1 (999) 999-9999"
                maskChar=" "
                value={phone.value}
                onChange={e => onPhoneChanged(e.target.value)}
              />
              {
                phone.invalid ?
                  <p className={'text-left'}>
                    <small className="text-sm font-sf-light text-red-800">Invalid phone number</small>
                  </p> : ''
              }
            </div>
          </div>

          <div className="container-half">
            <div className="w-full text-left">
              <InputLabel label="Confirm Phone Number" color="black" small/>
              <InputMask
                autofocus=""
                placeholder="(555) 555-5555"
                style={{outline: 0, fontFamily: 'SF-light'}}
                className="text-sm font-sf-light rounded input-mask form-control border block w-full px-2"
                mask="+1 (999) 999-9999"
                maskChar=" "
                value={phone.repeat}
                onChange={e => onRepeatPhoneChanged(e.target.value)}
              />
              {!phone.invalid && phone.value && phone.repeatInvalid ?
                <p className={'text-left'}>
                  <small className="text-sm font-sf-light text-red-800">Numbers don't match</small>
                </p> : ''
              }
            </div>
          </div>
        </div>
      </div>


      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Current Address" color="black" small/>
          <GooglePlacesInput
            placeholder='Enter Address'
            setData={setParentAddress}
          />
        </div>
      </div>

      {(!guest || provider.createAccount) && (
        <div className="mt-3">
          <InputLabel label="Password" small/>
          <input
            className="form-control appearance-none text-sm font-sf-light border border-light-gray rounded-md w-full py-3 px-3 text-primary-color leading-tight focus:outline-none"
            name="password"
            type="password"
            value={provider.password}
            placeholder="Password"
            onChange={e => onChange(e)}
            required
          />
        </div>
      )}

      {(!guest || provider.createAccount) && (
        <div className="mb-6 mt-3">
          <InputLabel label="Confirm Password" small/>
          <input
            className="form-control appearance-none text-sm font-sf-light border border-light-gray rounded-md w-full py-3 px-3 text-primary-color leading-tight focus:outline-none"
            name="passwordConfirm"
            type="password"
            value={provider.passwordConfirm}
            placeholder="Password Confirmation"
            onChange={e => onChange(e)}
            required
          />
        </div>
      )}

      {guest && (
        <label className="inline-flex items-center py-1 mt-3">
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4 "
            checked={provider.createAccount}
            onClick={() => dispatch({field: 'createAccount', value: !provider.createAccount})}
          />

          <span className="ml-2 text-primary-color text-sm font-sf-light">Create a trayvo Account</span>
        </label>
      )}

      <label class="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox text-primary-color h-5 w-5 md:h-6 md:h-6"
          checked={privacyChecked}
          onClick={() => changePrivacyChecked(!privacyChecked)}
        />
        <PrivacyText className="text-left mx-3"/>
      </label>
    </div>
  );
}

export default AccountParentInfo;
