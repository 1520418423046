//Below filter method is to filter all the slots which does not have the provided_service id inside the selected trainings array
//received. Work for availability tags feature
import {getFormattedLocalDate, getFormattedLocalDateTime} from '../../../utils/helpers/timeZone';

function filterSlots(slots, serviceId) {
  return slots.filter(slot => {
    let slotsAtDate = slot[1];
    if (slotsAtDate) {
      slotsAtDate = slotsAtDate.filter(({selected_trainings, from_time, free_slot}) => {
        return selected_trainings.includes(parseInt(serviceId)) && from_time && free_slot > 0;
      })
    }

    if (slotsAtDate?.length > 0) {
      slot[1] = slotsAtDate;
      return true;
    }

    return false;
  });
}

function rearrangeSlots(allSlots) {
  let newSlots = {};

  allSlots.forEach((slot, index) => {
    const slotDate = slot[0];
    const slots = slot[1];
    slots.forEach(timeSlot => {
      const localDate = getFormattedLocalDate(timeSlot.training_start_date_time, 'YYYY-MM-DD');
      const localDateTime = getFormattedLocalDateTime(timeSlot.training_start_date_time);

      if (!newSlots[localDate]) {
        newSlots[localDate] = [{localDateTime, referenceDate: slotDate, ...timeSlot}];
      } else {
        newSlots[localDate].push({localDateTime, referenceDate: slotDate, ...timeSlot});
      }
    });
  });

  const tmp = Object.entries(newSlots);
  tmp.sort((a, b) => {
    const aTs = new Date(a[0]).getTime();
    const bTs = new Date(b[0]).getTime();

    return aTs - bTs;
  })

  return tmp;
}

export function extractSlots(athelete, service) {
  if (service && athelete?.generated_slots) {
    const slots = Object.entries(athelete.generated_slots).filter(([key, value]) => {
      return value.length > 0;
    });

    const filteredSlots = filterSlots(slots, service.id)

    return rearrangeSlots(filteredSlots);
  }

  return [];
}
