import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from 'index';
import { Tiktok, TrayvoBlue } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/Shared/Loader';
import { TrainingBadge } from '../../Shared';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { observer } from 'mobx-react';
import { faPhone, faEnvelope, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import {
  priceFormatter,
  formatDate,
  borderColor,
  formatTime,
  getSocialUrl,
  getAthleteName,
  getCollegeName,
  getAthleteImageSrc,
  getHighSchoolName,
  formattedPositions,
  getLatLng,
} from '../../../utils/utilities';
import { getFormattedLocalDate, getFormattedLocalTime, getTimeZone } from 'utils/helpers/timeZone';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ContactTrainer from "../../Shared/ContactTrainer";

const TextContainer = ({ title, value }) => (
  <div className="flex justify-between my-1 p-3 rounded-md" style={{ backgroundColor: '#F5F6FA' }}>
    <span className="text-xs font-sf-regular text-primary-color">{title}</span>
    <span className="text-xs font-sf-regular text-primary-color normal-case text-right">
      {value}
    </span>
  </div>
);

const SmallText = ({ text = '', black, icon, id }) => (

  <div>
    <FontAwesomeIcon icon={icon} size="xs" color="gray" className="mr-2" />

    {id === 'email' ? (
      <button
        className="primary-text-regualar text-xs underline lowercase text-blue-700"
        onClick={() => {
          window.open(`mailto:${text}`);
        }}
        title={text}>
        {text}
      </button>
    ) : (
      <span
        className={`font-sf-regular text-xs capitalize mt-2 pr-1 ${black ? 'text-black' : 'text-gray-700'
          }`}
        style={{ fontSize: 11 }}>
        {text}
      </span>
    )}
  </div>
);

const AthleteDetail = ({ label, value }) => (
  <div className="flex flex-col border-b mt-3 border-gray-400 w-full items-start justify-end content-end">
    <small className={`font-sf-regular text-xs capitalize pr-1 mb-1 text-gray-600`}>{label}</small>

    <small className="font-sf-regular text-xs normal-case pr-1 text-primary-color">
      {value ? value : '-'}
    </small>
  </div>
);

const RoundImage = ({ source, trainingType, blueBorder, style }) => {
  return (
    <div
      className={`rounded-full border-2 mt-5  ${blueBorder ? 'border-blue-700' : borderColor(trainingType)
        }`}>
      <img
        alt=''
        style={style && style}
        src={source !== null ? source : !style ? TrayvoBlue : null}
        className="h-16 w-16 border rounded-full object-cover"
      />
    </div>
  );
};

const Caption = ({ caption }) => (
  <small className="font-sf-regular text-black text-left text-sm capitalize">{caption}</small>
);

function BookingsDetail(props) {
  let {
    match: { params },
  } = props;

  const [loader, setLoader] = useState(true);
  const [booking, setBooking] = useState(null);
  const [bookingGpsCoords, setBookingGpsCoords] = useState(null);
  const store = useContext(StoreContext);
  const history = useHistory();

  let service = booking?.provided_service;
  let athlete = booking?.athlete;

  const isPackage = booking?.package;

  function extractGpsCoords(booking) {
    if (booking.provided_service.training_type === 'virtual') {
      setBookingGpsCoords({
        lat: booking?.athlete.latitude,
        lng: booking?.athlete.longitude
      });
    } else {
      getLatLng(booking.address)
        .then((coords) => {
          setBookingGpsCoords(coords);
        });
    }
  }

  useEffect(() => {
    store.userStore.getBooking(params.id).then(booking => {
      if (booking.id) {
        setBooking(booking);

        service = booking?.provided_service;
        athlete = booking?.athlete;
        extractGpsCoords(booking);
      }
      setLoader(false);
    });
  }, []);

  return (
    <div className="bg-main-background pt-16">
      {!loader ? (
        <div className="w-full self-center flex flex-col items-center p-2 justify-center">
          {booking && service && athlete && (
            <div
              className="flex flex-col w-full md:w-3/5 md:flex-row p-4 md:py-4 justify-between bg-white shadow-md mt-5"
              style={{ borderRadius: 10 }}>
              {booking.status === 'incomplete' && booking.allowed_cancel ? (
                <>
                  <span
                    style={{ fontSize: 15 }}
                    className="font-sf-regular break-words text-t-gray text-xs mt-2 text-center">
                    Your booking cancellation request is approved. Click on button to cancel
                    booking.
                  </span>

                  <button
                    onClick={async () => {
                      await store.userStore.cancelBooking(booking.id);
                    }}
                    className="primary-button w-full md:w-32 mr-0 md:mr-3">
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <span
                    style={{ fontSize: 15 }}
                    className="font-sf-regular break-words text-t-gray text-xs mt-2 text-center">
                    Reach out to your trainer to discuss details of session or to cancel the
                    session.
                  </span>

                  <ContactTrainer />
                </>
              )}
            </div>
          )}

          {booking && service && athlete && (
            <div className="flex flex-col w-full md:w-3/5 md:flex-row p-4 md:p-0 justify-between bg-white shadow-md mt-5"
              style={{ borderRadius: 20 }}>

              <div className="mb-5 p-3 w-full md:w-1/2 relative">
                {isPackage &&
                  <div className="absolute left-0 top-0 right-auto overflow-hidden" style={{ width: 160, height: 160 }}>
                    <div className="capitalize font-sf-regular font-normal md:text-sm p-1 text-center border-2 border-solid bg-primary-color text-white z-50"
                      style={{
                        borderColor: '#eee',
                        transform: 'translateY(75%) translateX(-25%) rotate(-45deg)'
                      }}>package
                    </div>
                  </div>
                }

                <div className="flex flex-row">
                  {booking && (
                    <div className="px-2 flex flex-col w-full">
                      <div className="flex flex-col items-center mt-2">
                        <h4 className="font-sf-medium text-primary-color">Training Info</h4>

                        <RoundImage
                          source={service.image_url}
                          trainingType={service.training_type} />

                        <div className="flex flex-col w-full items-center mt-3">
                          <Caption caption={service.name} />

                          <div className="text-center max-location-height overflow-hidden">
                            <small className="font-sf-regular text-primary-color font-normal text-xs uppercase">
                              {parseInt(service?.duration) || 0} mins
                            </small>

                            {service.training_type !== 'virtual' &&
                              service.provided_service_locations &&
                              service.provided_service_locations.length > 0 &&
                              (
                                <small className="font-sf-regular text-primary-color font-normal text-xs text-center uppercase">
                                  <small className="text-primary-color px-1">@</small>
                                  {booking.location}
                                  <br />
                                  {booking.address}
                                </small>
                              )}
                          </div>

                          {<small className="text-black text-right font-sf-medium"
                            style={{ fontSize: 16 }}>
                            {priceFormatter(service?.price || 0)} {booking.package ? ' for ' + booking.sessions_count + ' sessions' : ''}
                          </small>}

                          <TrainingBadge trainingType={service.training_type} />
                        </div>
                      </div>

                      {service.training_type === 'virtual' && (
                        <span className="text-primary-color font-sf-regular text-center tex-xs capitalize">
                          Connection details: {service.connection_detail}
                        </span>
                      )}

                      <span
                        style={{ fontSize: 12 }}
                        className="font-sf-regular break-words text-t-gray text-xs mt-2 text-center">
                        {service.discription}
                      </span>
                    </div>
                  )}
                </div>

                <div className="py-3">
                  <div className="flex flex-col my-2 p-2">

                    {
                      booking.package ?
                        <TextContainer
                          title="Package ends"
                          value={getFormattedLocalDate(booking.training_end_date_time, 'MM-DD-YYYY')}
                        />
                        :
                        <TextContainer
                          title={getFormattedLocalDate(booking.training_start_date_time, 'MM-DD-YYYY')}
                          value={`${getFormattedLocalTime(booking.training_start_date_time)} - ${getFormattedLocalTime(booking.training_end_date_time)} (${getTimeZone()})`}
                        />
                    }

                    <TextContainer title="Price" value={priceFormatter(service?.price || 0)} />
                    <TextContainer title="Order" value={booking?.id} />
                    {/* <TextContainer title="Status" value={booking?.status} /> */}
                    <TextContainer title="Note" value={booking?.notes} />
                  </div>
                </div>
              </div>

              <div style={{ width: 1, backgroundColor: '#D7DAE2' }}
                className="my-10 hidden md:flex h-auto" />

              <div className="mb-5 p-2 h-auto w-full md:w-1/2">
                {athlete && (
                  <div className="w-full flex items-center flex-col p-3">
                    <h4 className="font-sf-medium text-primary-color mb-5">Trainer Info</h4>

                    {getAthleteImageSrc(athlete)}

                    <div className="flex flex-col w-full items-center mt-1">
                      <Caption caption={getAthleteName(athlete)} />

                      {!athlete.private_email && athlete?.email &&
                        <SmallText text={athlete.email || ''} icon={faEnvelope} id="email" />
                      }
                      {!athlete.private_contact_number && athlete?.contact_number &&
                        <SmallText text={athlete.contact_number || ''} icon={faPhone} />
                      }

                      {athlete?.city &&
                        <SmallText text={`${athlete.city || ''}${(athlete.city || '') && ', '} ${athlete.state || ''}`} icon={faLocationArrow} />}

                      <div className="flex items-center content-center pt-2">
                        {athlete.facebook &&
                          <Link to={{ pathname: `${getSocialUrl('facebook')}${athlete.facebook}` }}
                            className="fa fa-facebook"
                            rel="noopener noreferrer"
                            target="_blank" />}

                        {athlete.twitter &&
                          <Link to={{ pathname: `${getSocialUrl('twitter')}${athlete.twitter}` }}
                            className="fa fa-twitter"
                            rel="noopener noreferrer"
                            target="_blank" />}

                        {athlete.instagram &&
                          <Link to={{ pathname: `${getSocialUrl('instagram')}${athlete.instagram}` }}
                            className="fa fa-instagram"
                            rel="noopener noreferrer"
                            target="_blank" />}

                        {athlete.tiktok &&
                          <Link to={{ pathname: `${getSocialUrl('tiktok')}${athlete.tiktok}` }}
                            className="fa fa-tiktok"
                            rel="noopener noreferrer"
                            target="_blank">
                            <img src={Tiktok} alt='' className="w-4 h-4" />
                          </Link>}

                        {athlete.website &&
                          <Link to={{ pathname: athlete.website }}
                            rel="noopener noreferrer"
                            className="fa fa-link"
                            target="_blank" />}
                      </div>

                      <div className="w-3/4">
                        <AthleteDetail label="College" value={athlete?.college_name || '-'} />
                        <AthleteDetail label="High School" value={athlete?.high_school || '-'} />
                        <AthleteDetail label="Club" value={athlete?.club || '-'} />

                        <AthleteDetail label="Position" value={formattedPositions(athlete.position)} />
                        <AthleteDetail label="Class" value={athlete?.passing_year} />
                        <AthleteDetail label="Sport" value={athlete?.sport?.name} />
                        <AthleteDetail label="About" value={athlete?.profile_description} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {bookingGpsCoords ?
            <div className="flex flex-col w-full md:w-3/5 p-3 items-center bg-white shadow-md mt-2"
              style={{ borderRadius: 20 }}>
              <h5 className="text-black font-sf-regular">Location Info</h5>

              <div className="w-full">
                {athlete && (
                  <div id="mapBox" className="m-3">
                    <Map
                      google={props.google}
                      zoom={15}
                      style={{
                        width: '100%',
                        height: '250px',
                        borderRadius: 10,
                        position: 'relative',
                      }}
                      initialCenter={bookingGpsCoords}>
                      <Marker position={bookingGpsCoords} />
                    </Map>
                  </div>
                )}
              </div>
            </div> : ''
          }
        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(observer(BookingsDetail));
